import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../authentification/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  collapsed = true;
  isAuthenticated = false;
  faUser = fa.faUser;
  faLogout = fa.faSignOutAlt;
  faCar = fa.faCar;
  facharge = fa.faCoins;
  faChalkboardTeacher = fa.faChalkboardTeacher;
  faSetting = fa.faCogs;
  faReclamation = fa.faBullhorn;
  Sub: Subscription;
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(){
    this.authService.autoLogin(); 
    this.Sub = this.authService.user.subscribe(user => {
       this.isAuthenticated = !!user;
    });
  }

  ngOnDestroy(){
    this.Sub.unsubscribe();
  }

  onLogout(){
    this.authService.logout();
  }

}
