import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { CandidatStorageService } from 'src/app/candidats/condidat-storage.service';
import { Router } from '@angular/router';
import { ChargesStorageService } from 'src/app/charges/charges-storage.service';
import { VehiculeStorageService } from 'src/app/vehicules/Vehicule-storage.service';
import { MoniteurStorageService } from 'src/app/monitors/Moniteur-storage.service';
import { CandidatService } from 'src/app/candidats/candidat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  @Input() ParamNom: string;
  @Input() ParamCin: string;
  @Input() ParamKey: string;
  @Input() ParamPath : string;
  isLoading= false;
  keyP:string;
  subscription:Subscription;
  ListCandidats = [] ;
  @Output() close = new EventEmitter<void>();
  faclose = fa.faTimesCircle;
  fadelete = fa.faTrash;
  constructor(
    private candidatStorage : CandidatStorageService,
    private chargeStorage : ChargesStorageService,
    private VehiculeStorage : VehiculeStorageService,
    private moniteurStorage : MoniteurStorageService,
    private router : Router,
    private candidatService: CandidatService,
  ) { }

  ngOnInit() {
  }

  onClose(){
    this.close.emit();
  }

  deleteCandidatUser(){
    this.candidatStorage.GetCandidatsUser().subscribe();
    this.ListCandidats = this.candidatService.getListOfCandidatsU();
    this.subscription =  this.candidatService.SyncListCondidatsU.subscribe(
       ( Element  ) => {
        Element.filter( row => {
          if(row.Cin===this.ParamCin){
            this.candidatStorage.deleteCandidatElementUser(row.id);
          }
        })
      }) 
  }

  onDelete(){
    this.isLoading = true;
    if(this.ParamKey){
      if( this.ParamPath === 'Candidat' ){
        this.candidatStorage.deleteCandidatElement(this.ParamKey);
        this.deleteCandidatUser();
        //this.candidatStorage.deleteCandidatElementUser(this.ParamKey);
        this.isLoading = false;
        this.router.navigate(['/Candidats']);
      }
      if( this.ParamPath === 'Vehicule' ){
        //console.log(this.ParamPath,"--",this.ParamKey);
        this.VehiculeStorage.deleteVehiculeElement(this.ParamKey);
        this.router.navigate(['/Vehicules']);
        this.isLoading = false;
      }
      if( this.ParamPath === 'Charge' ){
        console.log(this.ParamPath,"--",this.ParamKey);
        this.chargeStorage.deleteChargeElement(this.ParamKey);
        this.router.navigate(['/Charges']);
        this.isLoading = false;
      }
      if( this.ParamPath === 'Moniteur' ){
        //console.log(this.ParamPath,"--",this.ParamKey);
        this.moniteurStorage.deleteMoniteurElement(this.ParamKey);
        this.router.navigate(['/Moniteurs']);
        this.isLoading = false;
      }      
    }
  }


}
