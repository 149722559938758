export class Moniteur {
    public Cin: string;
    public Nom: string;
    public Prenom: string;
    public PermisId: string;
    public CAP: Date;
    public Categorie: string;
    public ImagePath: string;
    public Date_enter:Date;
    public posted_by:string;
    public Code_Ecole:string;

    constructor(Cin: string, Nom: string, Prenom: string, PermisId: string, CAP: Date,Categorie:string,Date_enter:Date, ImagePath: string,posted_by: string , Code_Ecole : string){
        this.Cin = Cin;
        this.Nom = Nom;
        this.Prenom = Prenom;
        this.PermisId = PermisId;
        this.Categorie = Categorie;
        this.Date_enter = Date_enter;
        this.ImagePath = ImagePath;
        this.posted_by =posted_by ;
        this.Code_Ecole =Code_Ecole ;
        this.CAP = CAP;
    }
}