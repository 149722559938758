import { Component, OnInit, OnDestroy, AfterContentInit, ViewChild } from '@angular/core';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { CarburantStorageService } from '../carburant-storage.service';
import { CarburantService } from '../carburant.service';

@Component({
  selector: 'app-list-carburant',
  templateUrl: './list-carburant.component.html',
  styleUrls: ['./list-carburant.component.css']
})
export class ListCarburantComponent implements OnInit ,OnDestroy , AfterContentInit {

  @ViewChild('myDataTable',{static:false}) myDataTable: jqxDataTableComponent;
  faPrint = fa.faPrint;
  faExcel = fa.faFileExcel;
  faPdf = fa.faFilePdf;
  faEdit = fa.faEdit;
  StatusEditable = false;
  Rowselected:string ;
  ListCarburant = [] ;
  dataAdapter: any ;
  source = {
    dataType: 'array',
    //dataType: 'json',
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'Montant', type: 'string' },
      { name: 'Matricule', type: 'string' },
      { name: 'posted_by', type: 'string' },
      { name: 'Date_enter', type: 'date' },
    ],
    id: 'id',
    localdata: this.ListCarburant
    //url: '../assets/beverages.txt'
  };
  subscription :Subscription;
  constructor(
    private CarburantStorage: CarburantStorageService,
    private CarburantService: CarburantService
  ) { }

  ngOnInit() {
  }

  ngAfterContentInit(){
    this.getListCarburant();
  }
  

  getListCarburant(){
    this.CarburantStorage.GetCarburants().subscribe();
    this.ListCarburant = this.CarburantService.getListOfCarburant();
    this.subscription =  this.CarburantService.SyncListCarburant.subscribe(
       ( Element  ) => {
        this.ListCarburant =   Element;
        this.source.localdata = this.ListCarburant;
       //console.log(this.source.localdata )
        this.dataAdapter = new jqx.dataAdapter(this.source);
      }) 
  }  
  
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '100%';
    }
      return '100%';
    }
  
    exportSettings: any = { columnsHeader: true, hiddenColumns: false, characterSet: null, recordsInView: true, fileName: "myDataTable"};
  
    columns: any[] =
    [
      { text: 'Matricule', dataField: 'Matricule', width: '25%' , pinned: true  ,cellsalign: 'center' ,align: 'center'},
      { text: 'Montant', dataField: 'Montant', width: '25%' ,cellsalign: 'center' ,align: 'center'},
      { text: 'Posté par', dataField: 'posted_by', width: '25%' ,cellsalign: 'center' ,align: 'center'},
      { text: 'Date d\'entrer', dataField: 'Date_enter', width: '25%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd HH:mm' }
    ];
  
    onRowSelect(event: any): void {
      let args = event.args;
      let rowIndex = args.index;
      //console.log(args);
      this.Rowselected = args.key;
      this.StatusEditable = true;
      
    }
  
    printBtnOnClick(): void {
      const DateNow = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      let gridContent = this.myDataTable.exportData('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
          document = newWindow.document.open(),
          pageContent =
              '<!DOCTYPE html>' +
              '<html>' +
              '<head>' +
              '<meta charset="utf-8" />' +
              '<title>Auto Ecole Bouharat '+DateNow+'</title>' +
              '</head>' +
              '<body>' + gridContent + '</body></html>';
      document.write(pageContent);
      document.close();
      newWindow.print();
    };
  
    excelExport(): void {
        this.myDataTable.exportData('xls');
    };
  
    pdfExport(): void {
        this.myDataTable.exportData('pdf');
    };
  
  }