// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //firebaseAPIKey: 'AIzaSyAkMxLSmtMj6B6xuuKTXEfVw2wAuyITIMM',
  //firebaseProjectId: 'autoecole-ec821'
  firebaseAPIKey: 'AIzaSyDjzVSWSmlMoZy1LFoyvlkbZvd7ipWzHbY',
  firebaseProjectId: 'autoecolebouharat-3e298',
  Manager:'omarbouharat@bouharat.com',
  Admin:'admin@bouharat.com',
  Ecole1:'3909@bouharat.com',
  Ecole2:'4123@bouharat.com',
  Ecole3:'6746@bouharat.com',
  Ecole:[
    {code:'3909',Ecole : 'عمر بوحراث' ,  Director_Nom : 'الدغمومي'  , Director_Prenom : 'يوسف' , Adresse : 'تجزئة الداودية قطعة 121 اقامة ملاك طابق 1 رقم 04 طنجة' , Director_adresse : 'السواني 5 زنقة 65 رقم 01 طنجة' ,  TaxPro : '57159117' , RegistreCommerce : '95818' , Patente : '96/EEC/30' , Phone : '0539959828' , Fax : '0539959828'  } , 
    {code:'4123',Ecole : 'بوحرات' ,  Director_Nom : 'احليلق'  , Director_Prenom : 'حمزة' , Adresse : 'تجزئة كوليخليو انترناتو زنقة 33 رقم 25 طابق 2 طنجة' ,  Director_adresse : 'حي السعادة 01 الزنقة س رقم 11 طنجة' ,  TaxPro : '53541554' , RegistreCommerce : '170914' , Patente : '102/EEC/30' ,  Phone : '0707011125' , Phone_2 : '0672111125'  , Fax : '0539959525'  } , 
    {code:'6746',Ecole : 'بوحرات' ,  Director_Nom : 'العصعاص'  , Director_Prenom : 'محمد العربي' , Adresse : 'حي توسعة المنبار رقم القطعة 1040 الطابق الأول طنجة' ,  Director_adresse : 'حي المجد شارع القدس رقم 135 الطابق 03 الشقة 07 طنجة' , TaxPro : '51786643' , RegistreCommerce : '129493' , Patente : '225/EEC/30' , Phone : '0539959828'  , Fax : '0539959828'  } , 
  ],
  All:["3909","4123","6746"]
  };

 

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
