import { map,tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VehiculeService } from './vehicule.service';
import { Vehicule } from './vehicule.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class VehiculeStorageService {
    constructor(
        private http: HttpClient,
        private VehiculeService: VehiculeService
    ){}
    
    SaveVehicule(vehicule: Vehicule){
        return this.http
        .post(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/VEC.json',
            vehicule
            );
    }

    UpdateVehicule(key:string,vehicule: Vehicule){
      return this.http.patch(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/VEC/'+key+'.json'
          ,vehicule
          
      )
    }

    getVehiculeElement(key:string) {
        //console.log("Key "+key);
        return this.http.get<Vehicule>('https://'+environment.firebaseProjectId+'.firebaseio.com/VEC/'+key+'.json')
      }
      
    deleteVehiculeElement(key:string) {
        return this.http.delete('https://'+environment.firebaseProjectId+'.firebaseio.com/VEC/'+key+'.json')
        .subscribe();
      }

    GetVehicules() {
        return this.http
          .get<Vehicule>(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/VEC.json',
          )
          .pipe(
            map(response => {
                const postArray = [];
                for(const key in response){
                    if( response.hasOwnProperty(key) ){
                    response[key].ImagePath = this.VehiculeService.getImageCar(response[key].Modele) ;
                    postArray.push({...response[key],id: key});
                    }
                }
                return postArray;
            }),
            tap( Vehicules => {
                this.VehiculeService.setListOfVehicules(Vehicules);
            })
          );
    }
}