import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MonitorsComponent } from './monitors/monitors.component';
import { AuthentificationComponent } from './authentification/authentification.component';
import { ListVehiculesComponent } from './vehicules/list-vehicules/list-vehicules.component';
import { NewVehiculesComponent } from './vehicules/new-vehicules/new-vehicules.component';
import { VehiculesComponent } from './vehicules/vehicules.component';
import { ListMonitorsComponent } from './monitors/list-monitors/list-monitors.component';
import { NewMonitorsComponent } from './monitors/new-monitors/new-monitors.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { NewCandidatComponent } from './candidats/new-candidat/new-candidat.component';
import { ListCandidatsComponent } from './candidats/list-candidats/list-candidats.component';
import { CandidatsComponent } from './candidats/candidats.component';
import { AuthGuard } from './authentification/auth.gard';
import { ChargesComponent } from './charges/charges.component';
import { ListChargesComponent } from './charges/list-charges/list-charges.component';
import { NewChargesComponent } from './charges/new-charges/new-charges.component';
import { StatisticComponent } from './statistic/statistic.component';
import { ReclamationComponent } from './reclamation/reclamation.component';
import { ListCarburantComponent } from './vehicules/carburant/list-carburant/list-carburant.component';
import { TestComponent } from './shared/test/test.component';

const appRoutes: Routes = [
   { path: '', redirectTo: '/Dashboard', pathMatch: 'full' },

//  { path: 'Dashboard', component: ListCandidatsComponent , canActivate: [AuthGuard] },    
  { path: 'Dashboard', component: StatisticComponent , canActivate: [AuthGuard] },    
  
  {
    path: 'Vehicules',
    component: VehiculesComponent,
    children: [
      { path: '', component: ListVehiculesComponent , canActivate: [AuthGuard] },
      { path: 'New', component: NewVehiculesComponent , canActivate: [AuthGuard] },
      { path: 'Edit/:key', component: NewVehiculesComponent , canActivate: [AuthGuard] },
      { path: 'All', component: ListVehiculesComponent , canActivate: [AuthGuard] },
      { path: 'Carburant', component: ListCarburantComponent , canActivate: [AuthGuard] },
    ]
  },
  

  {
    path: 'Moniteurs',
    component: MonitorsComponent,
    children: [
      { path: '', component: ListMonitorsComponent  , canActivate: [AuthGuard] },
      { path: 'New', component: NewMonitorsComponent , canActivate: [AuthGuard] },
      { path: 'Edit/:key', component: NewMonitorsComponent , canActivate: [AuthGuard] },
      { path: 'All', component: ListMonitorsComponent  , canActivate: [AuthGuard] },
    ]
  },

  {
    path: 'Candidats',
    component: CandidatsComponent,
    children: [
      { path: '', component: ListCandidatsComponent , canActivate: [AuthGuard] },
      { path: 'New', component: NewCandidatComponent , canActivate: [AuthGuard]  },
      { path: 'Edit/:key', component: NewCandidatComponent , canActivate: [AuthGuard] },
      { path: 'All', component: ListCandidatsComponent , canActivate: [AuthGuard] },
    ]
  },

  {
    path: 'Charges',
    component: ChargesComponent,
    children: [
      { path: '', component: ListChargesComponent , canActivate: [AuthGuard] },
      { path: 'New', component: NewChargesComponent , canActivate: [AuthGuard]  },
      { path: 'Edit/:key', component: NewChargesComponent , canActivate: [AuthGuard] },
      { path: 'All', component: ListChargesComponent , canActivate: [AuthGuard] },
    ]
  },

  {
    path: 'Reclamation',
    component: ReclamationComponent,
    children: [
      { path: '', component: ReclamationComponent , canActivate: [AuthGuard] },
    ]
  },

    { path: 'auth', component: AuthentificationComponent },
    { path: 'test', component: TestComponent },
    { path: 'not-found' , component :  PageNotFoundComponent , canActivate: [AuthGuard] },
    { path: '**' , redirectTo : '/not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
