import { map,tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CandidatService } from './candidat.service';
import { Pointage } from '../shared/modal/pointage.model';
import { Candidat } from './candidat.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class CandidatStorageService {
    constructor(
        private http: HttpClient,
        private CandidatsService: CandidatService
    ){}
    
    SaveCandidat(candidat: Candidat){
       return this.http
        .post(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/CDT.json',
            candidat
            );
    }

    SaveCandidatUser(candidat: Candidat){
      return this.http
       .post(
           'https://'+environment.firebaseProjectId+'.firebaseio.com/CDTU.json',
           candidat
           );
     }

    SavePointage(pointage: Pointage){
      return this.http
       .post(
           'https://'+environment.firebaseProjectId+'.firebaseio.com/PT.json',
           pointage
           );
     }

    SavePointageUser(pointage: Pointage){
      return this.http
       .post(
           'https://'+environment.firebaseProjectId+'.firebaseio.com/PTU.json',
           pointage
           );
     }
    
    getCandidatElement(key:string) {
     // console.log("Key "+key);
      return this.http.get<Candidat>('https://'+environment.firebaseProjectId+'.firebaseio.com/CDT/'+key+'.json')
    }

    UpdateCandidat(key:string,candidat: Candidat){
      return this.http.patch(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CDT/'+key+'.json'
          ,candidat
          
      )
    }

    UpdateCandidatUser(key:string,candidat: Candidat){
      return this.http.patch(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CDTU/'+key+'.json'
          ,candidat
          
      )
    }
    
    deleteCandidatElement(key:string) {
      return this.http.delete('https://'+environment.firebaseProjectId+'.firebaseio.com/CDT/'+key+'.json')
      .subscribe();
    }

    deleteCandidatElementUser(key:string) {
      return this.http.delete('https://'+environment.firebaseProjectId+'.firebaseio.com/CDTU/'+key+'.json')
      .subscribe();
    }

    GetPointerList(cin:string) {
      return this.http
        .get<any>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/PT.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              let index:number = 0;
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                    if( response[key].Cin && response[key].Cin.toLowerCase() === cin.toLowerCase() ){
                     index += 1;
                     postArray.push({
                       'index': index,
                       'date_seance': new Date(response[key].DatePointage).toLocaleDateString()+" "+response[key].Heure+":"+response[key].Minutes
                      });
                    } 
                  }
              }
              return postArray;
          }),
          tap( condidats => {
            return condidats;
          })
        );
    }

    GetPointage() {
      return this.http
        .get<Pointage>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/PT.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                  postArray.push({...response[key],id: key});
                  }
              }
              return postArray;
          }),
          tap( PointageRes => {
              this.CandidatsService.setListOfPointages(PointageRes);
          })
        );
    }

    GetCandidatsUser() {
      return this.http
        .get<Candidat>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CDTU.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                  postArray.push({...response[key],id: key});
                  }
              }
              return postArray;
          }),
          tap( condidats => {
              this.CandidatsService.setListOfCandidatsU(condidats);
          })
        );
    }

    GetCandidats() {
      return this.http
        .get<Candidat>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CDT.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                  postArray.push({...response[key],id: key});
                  }
              }
              return postArray;
          }),
          tap( condidats => {
             // console.log(condidats);
              this.CandidatsService.setListOfCandidats(condidats);
          })
        );
    }

}