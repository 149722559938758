import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { AngularFireStorageReference, AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css']
})
export class WebcamComponent implements OnInit {

@Input() ParamName:string;
@Output() close = new EventEmitter<void>();
@Output() ProfilimagePath = new EventEmitter();

faWebcam = fa.faCamera;
faClose = fa.faTimes;
faSave = fa.faSave;
ref: AngularFireStorageReference;
task: AngularFireUploadTask;
ImgError = "";
// toggle webcam on/off
showWebcam = true;
isLoading = false;
allowCameraSwitch = false;
multipleWebcamsAvailable = false;
deviceId: string;
videoOptions: MediaTrackConstraints = {
  // width: {ideal: 1024},
  // height: {ideal: 576}
};

width: number;
height: number;

errors: WebcamInitError[] = [];

// latest snapshot
webcamImage: WebcamImage = null;

// webcam snapshot trigger
trigger: Subject<void> = new Subject<void>();
// switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

constructor( private afStorage: AngularFireStorage ){
  this.onResize();
}

@HostListener('window:resize', ['$event'])
onResize(event?: Event) {
  const win = !!event ? (event.target as Window) : window;
  this.width = win.innerWidth;
  this.height = win.innerHeight;
}

ngOnInit(){
  WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
}

triggerSnapshot() {
  this.trigger.next();
  this.showWebcam = false;
}

dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);   
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

handleInitError(error: WebcamInitError) {
  this.errors.push(error);
}

handleImage(webcamImage: WebcamImage) {
  //console.info('received webcam image', webcamImage);
  //console.log(webcamImage.imageAsDataUrl);
  this.webcamImage = webcamImage;

}

onUploadImage(){
  this.isLoading = true;
 // console.info('image', this.webcamImage);
  var ImageName = this.ParamName+".jpg";
  var file = this.dataURLtoFile(this.webcamImage.imageAsDataUrl,ImageName);
  this.ref = this.afStorage.ref(file.name);
  this.ref.put(file)
  .then(snapshot => {
      return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
  })
  .then(downloadURL => {
    this.isLoading = false;
    // console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
     this.ProfilimagePath.emit(downloadURL);
     this.close.emit();
     return downloadURL;
  })
  .catch(error => {
    this.isLoading = false;
    this.ImgError = "Une Erreur lors de la téléchargement de l'image";
     // Use to signal error if something goes wrong.
    // console.log(`Failed to upload file and get link - ${error}`);
  });
}

cameraWasSwitched(deviceId: string) {
 // console.log('active device: ' + deviceId);
  this.deviceId = deviceId;
}

public get triggerObservable(): Observable<void> {
  return this.trigger.asObservable();
}
  onClose(){
    this.close.emit();
  }

}
