import { map,tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChargesService } from './charges.service';
import { Charge } from './charges.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class ChargesStorageService {
    constructor(
        private http: HttpClient,
        private ChargesService: ChargesService
    ){}
     
    SaveCharge(Charge: Charge){
       return this.http
        .post(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/CH.json',
            Charge
            );
    }

    Updatecharge(key:string,Charge: Charge){
      return this.http.patch(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CH/'+key+'.json'
          ,Charge
          
      )
    }
    
    getChargeElement(key:string) {
      //console.log("Key "+key);
      return this.http.get<Charge>('https://'+environment.firebaseProjectId+'.firebaseio.com/CH/'+key+'.json')
    }
    
    deleteChargeElement(key:string) {
      return this.http.delete('https://'+environment.firebaseProjectId+'.firebaseio.com/CH/'+key+'.json')
      .subscribe();
    }

    GetCharges() {
      return this.http
        .get<Charge>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CH.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                  postArray.push({...response[key],id: key});
                  }
              }
              return postArray;
          }),
          tap( Charges => {
             // console.log(condidats);
              this.ChargesService.setListOfCharges(Charges);
          })
        );
    }

}