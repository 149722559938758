import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Candidat } from '../candidat.model';
import { Subscription } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CandidatStorageService } from '../condidat-storage.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/authentification/auth.service';
import { Credit } from './credit.model';
import { MoniteurService } from 'src/app/monitors/moniteur.service';
import { MoniteurStorageService } from 'src/app/monitors/Moniteur-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { VehiculeService } from 'src/app/vehicules/vehicule.service';
import { VehiculeStorageService } from 'src/app/vehicules/Vehicule-storage.service';
import { CandidatService } from '../candidat.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-new-candidat',
  templateUrl: './new-candidat.component.html',
  styleUrls: ['./new-candidat.component.css']
})
export class NewCandidatComponent implements OnInit, OnDestroy {
  @ViewChild('condidatFrm', { static: false }) condidatFrm: NgForm;
  //public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  datePickerConfig: Partial<BsDatepickerConfig>;
  NewCandidat = true;
  Date_P:string;
  date_enter:Date = new Date();
  Date_N_string:string;
  faEdit = fa.faUserEdit;
  faInvoice = fa.faFileInvoice;
  facertificat = fa.faGraduationCap;
  facontrat = fa.faFileContract;
  faDelete = fa.faUserTimes;
  faCamera = fa.faCamera;
  faInfo = fa.faInfoCircle;
  ListCandidats =[];
  ListCandidatUser=[];
  ListMoniteurs =[];
  ListVehicules =[];
  subscriptionV1 : Subscription;
  subscriptionV2 : Subscription;
  subscriptionM1 : Subscription;
  subscriptionM2 : Subscription;
  subscriptionC1 : Subscription;
  subscriptionC2 : Subscription;
  faCheck = fa.faCheck;
  faclear = fa.faEraser;
  faError = fa.faExclamationTriangle; 
  faPoint = fa.faHandPointer;
  faList = fa.faUsers;
  faLists = fa.faList;
  isLoading = false;
  postedBy:string;
  RowId:string;
  openmodal:string;
  openmodalPointage:string;
  opencreditModal:string;
  opendeleteModal:string;
  credit: Credit[] = [] ;
  Categories = ['A','B','C','D','E'];
  Codes_Ecole = [];
  StatusExamen = ['Apte','Inapte'];
  minDate: Date;
  montant_total:number = 0;
  currDate = Date();

  cin_edit:string;
  Code_Ecole_edit:string;
  nom_edit:string;
  prenom_edit:string;
  adresse_edit:string;
  VilleC_edit:string;
  LieuN_edit:string;
  EmailC_edit:string;
  telephoneC_edit:number;
  montant_edit:number;
  categorie_edit:string;
  vehiculeC_edit:string;
  capMC_edit:string;
  DateN_edit:Date;
  sexe_edit:string;
  statusExam_edit:string;
  NumeroContrat_edit:string;
  DateExTh1_edit:Date;
  DateExTh2_edit:Date;
  DateExP1_edit:Date;
  DateExP2_edit:Date;
  DateCtrl_edit:Date;
  tarif_edit:number;
  Maitre_P_edit:string;
  old_permis_edit:string;
  Maitre_Th_edit:string;
  error:string;
  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  imagePath = '../../../assets/img/profile.jpg';
  ImgError = "";
  creditStatus = false;
  showCamera:string;
  inlineOption = 'print-contrat';
  Profile ;

  constructor( 
    private authService: AuthService,
    private candidatStorage: CandidatStorageService,
    private moniteurService: MoniteurService,
    private moniteurStorage: MoniteurStorageService,
    private vehiculeService: VehiculeService,
    private vehiculeStorage: VehiculeStorageService,
    private route: ActivatedRoute,
    private router: Router,
    private candidatService: CandidatService,
    private afStorage: AngularFireStorage
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.datePickerConfig = Object.assign({}
      ,{
        containerClass: 'theme-default',
        showWeekNumbers: false ,
        adaptivePosition: true,
        dateInputFormat: 'DD/MM/YYYY',
        isAnimated: true,
      });
  }

  ngOnInit() {

    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));

   
    if (userData.email === environment.Manager) {
      this.Profile = {code:'All'}
      this.Codes_Ecole = environment.All
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
      this.Profile = environment.Ecole.find(item => item.code === Current_Code);
      this.Code_Ecole_edit = this.Profile.code
      this.Codes_Ecole.push(this.Profile.code)
    }


    this.getListMoniteurs();
    this.getListVehicules();
    this.RowId = this.route.snapshot.params['key'];
    if(this.RowId){
        this.subscriptionC1 = this.candidatStorage.getCandidatElement(this.RowId).subscribe(
          element => {
            if( element ){
            //  console.log(element);
              
              this.NewCandidat = false;
              this.date_enter = new Date(element.Date_entrer);
              let newDate = new Date(element.DateNaissance);
              this.DateN_edit = newDate;
              this.cin_edit = element.Cin;
              this.nom_edit = element.Nom;
              this.Code_Ecole_edit = element.Code_Ecole;
              this.Profile = environment.Ecole.find(item => item.code === this.Code_Ecole_edit);
              this.LieuN_edit = element.LieuNaissance;
              this.prenom_edit = element.Prenom;
              this.credit = element.Credit;
              this.adresse_edit = element.Adresse;
              this.VilleC_edit = element.Ville;
              this.EmailC_edit = element.Email;
              this.telephoneC_edit = element.Telephone;
              this.Date_P = new Date(element.Date_entrer).toLocaleDateString();
             // this.montant_edit = element.Montant;
              if(element.Montant_total) this.montant_total = element.Montant_total;
              if(element.Montant_total >= element.tarif) this.creditStatus = true;
              this.sexe_edit = element.Sexe;
              this.categorie_edit = element.CategoriePermis;
              this.vehiculeC_edit = element.Vehicule;
              this.capMC_edit = element.CapMoniteur;
              this.statusExam_edit = element.status;
              this.DateExTh1_edit = new Date(element.DateTh1_Examen);
              this.DateExTh2_edit = new Date(element.DateTh2_Examen);
              this.DateExP1_edit = new Date(element.DateP1_Examen);
              this.DateExP2_edit = new Date(element.DateP2_Examen);
              this.tarif_edit = element.tarif;
              this.Maitre_Th_edit = element.Maitre_Th;
              this.Maitre_P_edit = element.Maitre_P;
              this.old_permis_edit = element.old_permis;
              if(!element.ImagePath){
                this.imagePath = '../../../assets/img/profile.jpg';
              }else{
                this.imagePath = element.ImagePath;
              }
              this.Date_N_string = this.DateN_edit.toLocaleDateString();
              if(element.DateCtrl){
                this.DateCtrl_edit = new Date(element.DateCtrl);
              }
              this.NumeroContrat_edit = element.NumContrat;
            }
            //console.log(this.credit);
          }
         );
    }

  }

  ngOnDestroy(){
    if(this.subscriptionV1){
      this.subscriptionV1.unsubscribe();
    }
    if(this.subscriptionV2){
      this.subscriptionV2.unsubscribe();
    }
    if(this.subscriptionM1){
      this.subscriptionM1.unsubscribe();
    }
    if(this.subscriptionM2){
      this.subscriptionM2.unsubscribe();
    }
    if(this.subscriptionC1){
      this.subscriptionC1.unsubscribe();
    }
    if(this.subscriptionC2){
    //  this.subscriptionC2.unsubscribe();
    }
  }

  getImgLink($event){
    if($event){
      this.imagePath = $event;
    }
  }

  upload(event){
    this.ImgError = "";
    const id = event.target.files[0].name;
    this.ref = this.afStorage.ref(id);
    //console.log(event.target.files[0]);
    //return;
    var ImgType = event.target.files[0].type.split('/')[0];    
    if(ImgType !== 'image') {
      this.ImgError = "Désolé, invalide image";
      return;
    }
    if(event.target.files[0]){
      this.imagePath = '../../../assets/img/loading.gif';
      this.ref.put(event.target.files[0])
      .then(snapshot => {
          return snapshot.ref.getDownloadURL();
      })
      .then(downloadURL => {
         //console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
         this.imagePath = downloadURL;
         return downloadURL;
      })
      .catch(error => {
        this.ImgError = "Une Erreur lors de la téléchargement de l'image";
        // console.log(`Failed to upload file and get link - ${error}`);
      });
    }
  }

  onCloseModal(){
    this.openmodal = null ;
    this.opendeleteModal = null ;
    this.opencreditModal = null;
    this.openmodalPointage = null;
    this.showCamera = null;
  }

  getListVehicules(){
    //console.log('Get Vehicules List')
    this.subscriptionV1 = this.vehiculeStorage.GetVehicules().subscribe();
    this.ListVehicules = this.vehiculeService.getListOfVehicules();
    this.subscriptionV2 = this.vehiculeService.SyncListVehicule.subscribe(
      ( Element  ) => {
        var Items = [];
        for(var i=0;i<Element.length;i++){
          if(Element[i].Code_Ecole === this.Profile.code ){
            Items.push(Element[i]);
          }
        }
        this.ListVehicules = Items;
    })
  }

  /*
  onChange(NewValue) {
    console.log(NewValue);
    this.Code_Ecole_edit = NewValue
    this.getListVehicules()
    this.getListMoniteurs()
  }
  */

  getListMoniteurs(){
    this.subscriptionM1 = this.moniteurStorage.GetMoniteurs().subscribe();
    this.ListMoniteurs = this.moniteurService.getListOfMoniteurs();
    this.subscriptionM2 = this.moniteurService.SyncListMoniteur.subscribe(
      ( Element  ) => {
        var Items = [];
        for(var i=0;i<Element.length;i++){
          if(Element[i].Code_Ecole === this.Profile.code ){
            Items.push(Element[i]);
          }
        }
        this.ListMoniteurs = Items;
    })
  }

  onReset(form: NgForm){
     form.reset();
  }

  onSubmit(form: NgForm) {    
    if( this.NewCandidat ){
      //console.log("Mode New");
      var acct = form.value.cin.toLowerCase().trim();
      const newEmailUser = acct+"@bouharat.com";
      //console.log(newEmailUser,"----",acct);
      this.AddNewCandidat(form,'new');
      this.authService.signUp(newEmailUser,acct)
      .subscribe(
        resData => {
          // console.log(resData);
        },
        errorMessage => {
          this.error = errorMessage;
        }
      );
    }else{
      this.AddNewCandidat(form,'update',this.RowId);
      //this.AddNewCandidat(form);
      //this.DeleteCandidat();
    }
    
  }

  onNewCredit($event){
      //console.log($event);
      if($event){
        this.credit = $event;
      }
  }

  onItemChange(event){
    this.inlineOption = event.target.value;
  }

  DeleteCandidat(){
    this.candidatStorage.deleteCandidatElement(this.RowId);
    this.candidatStorage.deleteCandidatElementUser(this.RowId);
    this.router.navigate(['/Candidats']);
  }


  UpdateCanddiatUser(ParamCin:string,NewCandidat:Candidat){
    this.candidatStorage.GetCandidatsUser().subscribe();
    this.ListCandidatUser = this.candidatService.getListOfCandidatsU();
    this.candidatService.SyncListCondidatsU.subscribe(
       ( Element  ) => {
        Element.filter( row => {
          if(row.Cin===ParamCin){
            this.candidatStorage.UpdateCandidatUser(row.id,NewCandidat)
            .subscribe(response => {
              this.isLoading = false;
             });
          }
        })
      }) 
  }

  AddNewCandidat(form: NgForm,type:string,key?:string){
    //console.log(form.value.Maitre_Th);
    this.postedBy = this.authService.getSessionLogged();
    this.isLoading = true;
    //this.montant_total += form.value.montant;
    //console.log("-----------",this.montant_total)
    this.montant_total = 0 ;
    if(form.value.montant){
      if( form.value.montant > 0){
        this.credit.push(new Credit(form.value.montant,new Date()));
      }
    }else{
      this.montant_total += 0;
    }
    if(this.credit){
      this.credit.filter(mt_tmp => {
        this.montant_total += mt_tmp.Amount;
      })
    }
    if(type === 'new'){
      const NewCandidat = new Candidat(form.value.NumeroContrat,form.value.cin,form.value.nom,form.value.prenom,form.value.sexe,form.value.DateN,form.value.LieuN,form.value.adresse,form.value.VilleC,form.value.EmailC,form.value.telephoneC,form.value.vehiculeC,form.value.categorie,form.value.capMC,form.value.montant,this.imagePath,this.postedBy,this.credit,this.date_enter,form.value.DateExTh1,form.value.DateExTh2,form.value.DateExP1,form.value.DateExP2,form.value.DateCtrl,form.value.statusExam,form.value.tarif,this.montant_total,form.value.Maitre_Th,form.value.Maitre_P,new Date(),form.value.old_permis , form.value.Code_Ecole);
      //console.log(NewCandidat);
      this.candidatStorage.SaveCandidat(NewCandidat)
      .subscribe( response => {
        this.isLoading = false;
      });
      this.candidatStorage.SaveCandidatUser(NewCandidat)
      .subscribe( response => {
        this.isLoading = false;
      });
    }
    if(type === 'update'){
      const NewCandidat = new Candidat(form.value.NumeroContrat,form.value.cin,form.value.nom,form.value.prenom,form.value.sexe,form.value.DateN,form.value.LieuN,form.value.adresse,form.value.VilleC,form.value.EmailC,form.value.telephoneC,form.value.vehiculeC,form.value.categorie,form.value.capMC,form.value.montant,this.imagePath,this.postedBy,this.credit,this.date_enter,form.value.DateExTh1,form.value.DateExTh2,form.value.DateExP1,form.value.DateExP2,form.value.DateCtrl,form.value.statusExam,form.value.tarif,this.montant_total,form.value.Maitre_Th,form.value.Maitre_P,new Date(),form.value.old_permis , form.value.Code_Ecole);
      this.candidatStorage.UpdateCandidat(key,NewCandidat)
      .subscribe(response => {
        this.isLoading = false;
       });
      this.UpdateCanddiatUser(form.value.cin,NewCandidat);
/*
       this.candidatStorage.UpdateCandidatUser(key,NewCandidat)
      .subscribe(response => {
        this.isLoading = false;
       });
       */

    }    
  }
}
