import { map,tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MoniteurService } from './moniteur.service';
import { Moniteur } from './Moniteur.model';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class MoniteurStorageService {
    constructor(
        private http: HttpClient,
        private MoniteurService: MoniteurService
    ){}
    
    SaveMoniteur(moniteur: Moniteur){
       return this.http
        .post(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/MNT.json',
            moniteur
            );
    }

    getMoniteurElement(key:string) {
      //console.log("Key "+key);
      return this.http.get<Moniteur>('https://'+environment.firebaseProjectId+'.firebaseio.com/MNT/'+key+'.json')
    }

    UpdateMoniteur(key:string,moniteur: Moniteur){
      return this.http.patch(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/MNT/'+key+'.json'
          ,moniteur
          
      )
    }
    
    deleteMoniteurElement(key:string) {
      return this.http.delete('https://'+environment.firebaseProjectId+'.firebaseio.com/MNT/'+key+'.json')
      .subscribe();
    }

    GetMoniteurs() {
      return this.http
        .get<Moniteur>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/MNT.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                  postArray.push({...response[key],id: key});
                  }
              }
              return postArray;
          }),
          tap( moniteurs => {
             // console.log(moniteurs);
              this.MoniteurService.setListOfMoniteurs(moniteurs);
          })
        );
    }
}