import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/authentification/auth.service';
import { ChargesStorageService } from '../charges-storage.service';
import { Charge } from '../charges.model';

@Component({
  selector: 'app-new-charges',
  templateUrl: './new-charges.component.html',
  styleUrls: ['./new-charges.component.css']
})
export class NewChargesComponent implements OnInit, OnDestroy {
  @ViewChild('ChargesFrm', { static: false }) ChargesFrm: NgForm;
  NewCharge = true;
  faEdit = fa.faEdit;
  faDelete = fa.faTrashAlt;
  faCheck = fa.faCheck;
  faclear = fa.faEraser;
  opendeleteModal:string;
  isLoading = false;
  postedBy:string;
  RowId:string;
  subscription:Subscription;

  titre_edit:string;
  Montant_edit:number;
  description_edit:string;

  constructor( 
    private authService: AuthService,
    private chargeStorage: ChargesStorageService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
  }

  ngOnInit() {
    this.RowId = this.route.snapshot.params['key'];
    if(this.RowId){
       this.subscription =  this.chargeStorage.getChargeElement(this.RowId).subscribe(
          element => {
            //console.log(element);
            if( element ){
              this.NewCharge = false;
              this.titre_edit = element.label;
              this.description_edit = element.description;
              this.Montant_edit = element.Montant;
    
            }
          }
        );
    }
  }

  ngOnDestroy(){
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onCloseModal(){
    this.opendeleteModal = null ;
  }

  uniqId() {
    return Math.random().toString(36).substr(2, 9);
  }

  onReset(form: NgForm){
     form.reset();
  }

  ondelete(form: NgForm){
    if( !this.NewCharge ){
      if(confirm("Voulez-vous vraiment supprimer cet charge "+this.titre_edit+" ?")){
        this.DeleteCharge();
      }
    }
  }

  onSubmit(form: NgForm) {    
    if( this.NewCharge ){
      //console.log("Mode New");
      this.AddNewCharge(form,'new');
    }else{
      //console.log("Mode Update"+this.RowId);
      this.AddNewCharge(form,'update',this.RowId);
      //this.AddNewCharge(form);
     // this.DeleteCharge();
    }
    
  }

  DeleteCharge(){
    this.chargeStorage.deleteChargeElement(this.RowId);
    this.router.navigate(['/Charges']);
  }

  AddNewCharge(form: NgForm,type:string,key?:string){
    this.postedBy = this.authService.getSessionLogged();
    this.isLoading = true;
    const NewCharge = new Charge(form.value.label,form.value.Montant,form.value.description,this.postedBy,new Date());
    if(type === 'new'){
      this.chargeStorage.SaveCharge(NewCharge)
      .subscribe( response => {
        this.isLoading = false;
      });
    }
    if(type === 'update'){
      this.chargeStorage.Updatecharge(key,NewCharge)
      .subscribe(response => {
        this.isLoading = false;
       });
    }

    

  }
}

