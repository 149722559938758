import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { CandidatStorageService } from '../condidat-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pointage',
  templateUrl: './pointage.component.html',
  styleUrls: ['./pointage.component.css']
})
export class PointageComponent implements OnInit {
  @Input() ParamNom: string;
  @Input() ParamCin: string;
  isLoading= false;
  @Output() close = new EventEmitter<void>();
  faclose = fa.faTimesCircle;
  faStatus = fa.faCheck;
  sub : Subscription;
  fadelete = fa.faTrash;
  listSeances: { index: number, date_seance: string } [] = [
    { index:1, date_seance: "" },
    { index:2, date_seance: "" },
    { index:3, date_seance: "" },
    { index:4, date_seance: "" },
    { index:5, date_seance: "" },
    { index:6, date_seance: "" },
    { index:7, date_seance: "" },
    { index:8, date_seance: "" },
    { index:9, date_seance: "" },
    { index:10, date_seance: ""  },
    { index:11, date_seance: ""  },
    { index:12, date_seance: ""  },
    { index:13, date_seance: ""  },
    { index:14, date_seance: ""  },
    { index:15, date_seance: ""  },
    { index:16, date_seance: ""  },
    { index:17, date_seance: ""  },
    { index:18, date_seance: ""  },
    { index:19, date_seance: ""  },
    { index:20, date_seance: ""  }
  ];


  constructor(
    private candidatStorage : CandidatStorageService

  ) { }

  ngOnInit() {
    this.sub = this.candidatStorage.GetPointerList(this.ParamCin).subscribe(
      (el:any) => {
        this.listSeances.filter(
          Row => {
            for(const element in el){
             
              if( el[element].index === Row.index ){
                Row.date_seance = el[element].date_seance;
              }
            }
          }
        );
      }
    );                        
  }

  onClose(){
    this.close.emit();
  }

}
