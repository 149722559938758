import { Component, OnInit, ViewChild, AfterContentInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import { ReclamationStorage } from './reclamation-storage.service';
import { ReclamationSerivce } from './reclamation.service';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reclamation',
  templateUrl: './reclamation.component.html',
  styleUrls: ['./reclamation.component.css']
})
export class ReclamationComponent implements OnInit, OnDestroy , AfterContentInit {
  
  @ViewChild('myDataTable',{static:false}) myDataTable: jqxDataTableComponent;

  showReclamation = null;
  FullName :string;
  date_enter:string;
  title:string;
  message:string;
  StatusEditable = false;
  faView = fa.faEye;
  Rowselected:string ;
  ListReclamation = [] ;
  dataAdapter: any ;
  source = {
    dataType: 'array',
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'cin', type: 'string' },
      { name: 'fullName', type: 'string' },
      { name: 'titre', type: 'string' },
      { name: 'message', type: 'string' },
      { name: 'status', type: 'number' },
      { name: 'date_enter', type: 'date' }
    ],
    id: 'id',
    localdata: this.ListReclamation,
    sortcolumn: 'status',
    sortdirection: 'desc'
  };
  subscription :Subscription;
  constructor(
    private ReclamationStorage: ReclamationStorage ,
    private ReclamationService: ReclamationSerivce
  ) { }

  ngOnInit() {
  }

  ngAfterContentInit(){
    this.getListReclamation();
  }

  onCloseModal(){
    this.showReclamation = null ;
  }

  getListReclamation(){
    this.ReclamationStorage.GetReclamations().subscribe();
    this.ListReclamation = this.ReclamationService.getListOfReclamations();
    this.subscription =  this.ReclamationService.SyncListReclamations.subscribe(
       ( Element  ) => {
        this.ListReclamation =   Element;
        this.source.localdata = this.ListReclamation;
        this.dataAdapter = new jqx.dataAdapter(this.source);
      }) 
  }  
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '100%';
    }
      return '100%';
  }

  cellClass = (row: any, dataField: any, cellText: any, rowData: any): string => {
    let cellValue = rowData[dataField];
    switch (dataField) {
        case 'status':
            if (cellValue == 0) {
                return 'NotOk';
            }
            return 'Ok';
    }
  }
  
  columns: any[] =
  [
    { text: 'Status', dataField: 'status', width: '5%' ,cellClassName: this.cellClass, height: '50px' ,cellsalign: 'center' ,align: 'center'},
    { text: 'CIN', dataField: 'cin', width: '7%' ,cellsalign: 'center' ,pinned: true ,align: 'center' },
    { text: 'Nom et Prenom', dataField: 'fullName', width: '18%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'Titre', dataField: 'titre', width: '15%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Message', dataField: 'message', width: '44.5%' , height: '50px' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Date d\'entrer', dataField: 'date_enter', width: '10%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd HH:mm' }
  ];

  onRowSelect(event: any): void {
    let args = event.args;
    // console.log(args);
    // console.log(args.row.cin);
    this.Rowselected = args.key;
    this.FullName = args.row.fullName;
    this.title = args.row.titre;
    this.message = args.row.message;
    this.date_enter =  new Date(args.row.date_enter).toLocaleDateString()+" "+new Date(args.row.date_enter).toLocaleTimeString() ;
    this.StatusEditable = true;
    
  }


}