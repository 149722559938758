import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-credit-modal',
  templateUrl: './credit-modal.component.html',
  styleUrls: ['./credit-modal.component.css']
})
export class CreditModalComponent implements OnInit {

  @Input() Param: string;
  @Output() close = new EventEmitter<void>();
  @Output() newCredit = new EventEmitter<string>();
  faclose = fa.faTimesCircle;
  fadelete = fa.faTrashAlt;

  constructor() { }

  ngOnInit() {
  }

  onDelete(index:number){
    this.Param = this.Param.slice(0,index);
  }

  onClose(){
    this.newCredit.emit(this.Param);
    this.close.emit();
  }

}
