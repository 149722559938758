export class Vehicule {
    public Matricule: string;
    public Marque: string;
    public Modele: string;
    public Categorie: string;
    public Date_enter: Date;
    public ImagePath: string;
    public posted_by:string;
    public D_visite:Date;
    public D_assurance:Date;
    public km_actuel:number;
    public km_prochaine:number;
    public km_dernier:number;
    public Code_Ecole:string;

    constructor(Matricule: string, Marque: string, Modele: string, Categorie: string, Date_enter: Date, ImagePath: string,posted_by: string,D_visite:Date,D_assurance:Date,km_actuel:number,km_prochaine:number,km_dernier:number , Code_Ecole : string){
        this.Matricule = Matricule;
        this.Marque = Marque;
        this.Modele = Modele;
        this.Categorie = Categorie;
        this.Date_enter = Date_enter;
        this.ImagePath = ImagePath;
        this.posted_by = posted_by;
        this.D_assurance = D_assurance;
        this.km_actuel = km_actuel;
        this.km_prochaine = km_prochaine
        this.D_visite = D_visite;
        this.km_dernier = km_dernier;
        this.Code_Ecole = Code_Ecole;
    }
}