import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-inventaire-details',
  templateUrl: './inventaire-details.component.html',
  styleUrls: ['./inventaire-details.component.css']
})
export class InventaireDetailsComponent implements OnInit {

  @Input() Inscription: any[] = [];
  @Input() Depenses: any[] = [];

  @Input() Index: number;
  @Input() monthName:string;
  D_inscription = [];
  D_Depenses = [];
  D_Revenue = [];
  D_Apte = [];
  D_Inapte = [];
  D_quota = [];
  @Output() close = new EventEmitter<void>();
  faclose = fa.faTimesCircle;
  constructor(
  ) { }

  ngOnInit() {
    this.onPrepare(this.Index);
  }

  onPrepare(index:number){
    if(this.Index){
      this.Inscription.filter( Row => {
        if(Row.Month === this.Index){
          this.D_inscription.push(Row.Data);
          if(Row.Data.status === 'Apte'){
            this.D_Apte.push(Row.Data)
          }
          if(Row.Data.status === 'Inapte'){
            this.D_Inapte.push(Row.Data)
          }
          if((Row.Data.DateCtrl)){
            this.D_quota.push(Row.Data)
          }
        }
      })
      this.Depenses.filter( Row => {
        if(Row.Month === this.Index){
          this.D_Depenses.push(Row.Data);
        }
      })
      /*
      this.Exam.filter( Row => {
        if(Row.Month === this.Index){
          if(Row.type === 'Apte'){
            this.D_Apte.push(Row.Data)
          }
          if(Row.type === 'Inapte'){
            this.D_Inapte.push(Row.Data)
          }
          if(Row.type === 'quota'){
            this.D_quota.push(Row.Data)
          }
        }
      })
      */
    }
  }

  onClose(){
    this.close.emit();
  }

}
