import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChargesService{
    constructor(
    ){}
;
    private ListCharges = [];
    SyncListCharges = new Subject<any>();
    
    getListOfCharges(){
        this.SyncListCharges.next(this.ListCharges.slice());
        return this.ListCharges.slice();
    }

    setListOfCharges(ChargesValue) {
        this.ListCharges = ChargesValue ;
        this.SyncListCharges.next(this.ListCharges.slice());
    }

}