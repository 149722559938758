export class Pointage {
    public Cin: string;
    public DatePointage: Date;
    public Heure: number;
    public Minutes: number;
    public posted_by:string;
    public Date_entrer:Date;

    constructor(Cin: string, DatePointage:Date,Heure : number, Minutes : number,posted_by : string, Date_entrer : Date){
        this.Cin = Cin;
        this.DatePointage = DatePointage;
        this.Minutes = Minutes;
        this.Heure = Heure;
        this.posted_by = posted_by;
        this.Date_entrer = Date_entrer;
    }
}