import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VehiculeService{
    constructor(
    ){}
;
    private ListVehicules = [];
    SyncListVehicule = new Subject<any>();
    CarImage:{ Modele: string, image: string }[] = [
        {Modele:'208',image:'208.jpg'},
        {Modele:'sandero',image:'sandero.jpg'},
        {Modele:'polo',image:'polo.jpg'},
        {Modele:'ibiza',image:'ibiza.jpg'},
        {Modele:'clio',image:'clio.jpg'},
        {Modele:'clio 4',image:'clio.jpg'},
        {Modele:'c3',image:'c3.jpg'},
      ]
    
    getListOfVehicules(){
        this.SyncListVehicule.next(this.ListVehicules.slice());
        return this.ListVehicules.slice();
    }

    setListOfVehicules(VehiculeValue) {
        this.ListVehicules = VehiculeValue ;
        this.SyncListVehicule.next(this.ListVehicules.slice());
    }

    getImageCar(modele:string){
        let result = "../../../assets/img/cars/noImage.jpg";
        this.CarImage.filter(
          obj => { 
            if(modele.toLowerCase() === obj.Modele.toLowerCase()){
              result = "../../../assets/img/cars/"+obj.image;
            }
          }
        );
        return result;
      }
}