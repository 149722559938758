import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VehiculeStorageService } from '../Vehicule-storage.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/authentification/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Vehicule } from '../vehicule.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-vehicules',
  templateUrl: './new-vehicules.component.html',
  styleUrls: ['./new-vehicules.component.css']
})
export class NewVehiculesComponent implements OnInit , OnDestroy {
  NewVehicule = true;
  datePickerConfig: Partial<BsDatepickerConfig>;
  RowId:string;
  faCheck = fa.faCheck;
  faclear = fa.faEraser;
  faEdit = fa.faCarSide;
  faDelete = fa.faCarSide;
  Categories = ['A','B','C','D','E'];
  Codes_Ecole = [];
  isLoading = false;
  postedBy :string;
  Marque_edit:string;
  Modele_edit:string;
  Matricule_edit:string;
  Categorie_edit:string;
  subscription:Subscription;
  Datevisite_edit:Date;
  km_prochaine_edit:number;
  km_actuel_edit:number;
  km_dernier_edit:number;
  DatAssurance_edit:Date;
  Code_Ecole_edit:string;
  opendeleteModal:string;

  Profile ;

  constructor(
    private authService: AuthService,
    private VehiculeStorage : VehiculeStorageService,
    private router: Router,
    private route: ActivatedRoute
  ) { 
    this.datePickerConfig = Object.assign({}
      ,{
        containerClass: 'theme-default',
        showWeekNumbers: false ,
        adaptivePosition: true,
        dateInputFormat: 'DD/MM/YYYY',
        isAnimated: true,
      });
  }


  ngOnInit() {

    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));


    if (userData.email === environment.Manager) {
      this.Profile = {code:'All'}
      this.Codes_Ecole = environment.All
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
       this.Profile = environment.Ecole.find(item => item.code === Current_Code);
       this.Code_Ecole_edit = this.Profile.code
       this.Codes_Ecole.push(this.Profile.code)
    }

    this.RowId = this.route.snapshot.params['key']
    this.subscription =  this.VehiculeStorage.getVehiculeElement(this.RowId).subscribe(
      element => {
        if( element ){
          this.NewVehicule = false;
          this.Marque_edit = element.Marque;
          this.Modele_edit = element.Modele;
          this.Matricule_edit = element.Matricule;
          this.Categorie_edit = element.Categorie;
          this.Code_Ecole_edit = element.Code_Ecole;
          this.Datevisite_edit = new Date(element.D_visite);
          this.DatAssurance_edit = new Date(element.D_assurance);
          this.km_actuel_edit = element.km_actuel;
          this.km_prochaine_edit = element.km_prochaine;
          this.km_dernier_edit = element.km_dernier;
        }
      }
     );
  }

  ngOnDestroy(){
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
 }

  onReset(form: NgForm){
    form.reset();
  }

  onSubmit(form: NgForm) {    
    if( this.NewVehicule ){
      //console.log("Mode New");
      this.AddNewMoniteur(form,'new');
    }else{
      //console.log("Mode Update"+this.RowId);
      this.AddNewMoniteur(form,'update',this.RowId);
      //  this.AddNewMoniteur(form);
      //  this.DeleteMoniteur();
    }
  }

  onCloseModal(){
    this.opendeleteModal = null ;
  }

  DeleteMoniteur(){
    this.VehiculeStorage.deleteVehiculeElement(this.RowId);
    this.router.navigate(['/Vehicules']);
  }

  AddNewMoniteur(form: NgForm,type:string,key?:string){
    this.postedBy = this.authService.getSessionLogged();
    this.isLoading = true; 
    const NewVehicule = new Vehicule(form.value.Matricule,form.value.Marque,form.value.Modele,form.value.Categorie,new Date(),'',this.postedBy,form.value.Datevisite,form.value.DatAssurance,form.value.km_actuel,form.value.km_prochaine,form.value.km_dernier , form.value.Code_Ecole);
    console.log(NewVehicule);
    if(type === 'new'){
      this.VehiculeStorage.SaveVehicule(NewVehicule)
      .subscribe(response => {
        this.isLoading = false;
       });
    }
    if(type === 'update'){
      this.VehiculeStorage.UpdateVehicule(key,NewVehicule)
      .subscribe(response => {
        this.isLoading = false;
       });
    }


  }
}
