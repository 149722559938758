import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CarburantService{
    constructor(
    ){}
;
    private ListCarburant = [];
    SyncListCarburant = new Subject<any>();
    
    getListOfCarburant(){
        this.SyncListCarburant.next(this.ListCarburant.slice());
        return this.ListCarburant.slice();
    }

    setListOfCarburant(CarburantValue) {
        this.ListCarburant = CarburantValue ;
        this.SyncListCarburant.next(this.ListCarburant.slice());
    }
    

}