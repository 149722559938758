import { Component } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-monitors',
  templateUrl: './monitors.component.html',
  styleUrls: ['./monitors.component.css']
})
export class MonitorsComponent {
  faNew = fa.faPlus;
  faList = fa.faBars;
    constructor() { 
    }
  
}