

export class Charge {
    public label: string;
    public Montant: number;
    public description: string;
    public Date_enter:Date;
    public posted_by:string;


    constructor(label:string,Montant:number,description:string,posted_by:string,Date_enter:Date){
        this.label=label;
        this.Montant = Montant;
        this.description = description;
        this.Date_enter = Date_enter
        this.posted_by = posted_by ;
    }
}