import { Credit } from './new-candidat/credit.model';

export class Candidat {
    public NumContrat: string
    public Cin: string;
    public Nom: string;
    public Prenom: string;
    public Sexe: string;
    public DateNaissance: string;
    public LieuNaissance: string;
    public Adresse: string;
    public Ville: string;
    public Email: string;
    public Telephone: number;
    public Vehicule: string;
    public CategoriePermis: string;
    public CapMoniteur: string;
    public Montant: number;
    public Montant_total: number;
    public tarif:number;
    public ImagePath: string;
    public posted_by:string;
    public Date_entrer:Date;
    public Date_modified:Date;
    public DateTh1_Examen:Date;
    public DateTh2_Examen:Date;
    public DateP1_Examen:Date;
    public DateP2_Examen:Date;
    public DateCtrl:Date;
    public status:string;
    public Maitre_Th:string;
    public Maitre_P:string;
    public Code_Ecole:string;
    public Credit : Credit[];
    public old_permis:string;


    constructor(NumContrat:string,Cin: string, Nom: string, Prenom: string, Sexe: string, DateNaissance: string, LieuNaissance: string, Adresse: string, Ville: string, Email: string, Telephone: number, Vehicule: string, CategoriePermis: string, CapMoniteur: string, Montant: number, ImagePath: string,posted_by: string,Credit: Credit[],Date_entrer:Date,DateTh1_Examen:Date,DateTh2_Examen:Date,DateP1_Examen:Date,DateP2_Examen:Date,DateCtrl:Date,status:string,tarif:number,Montant_total:number,Maitre_Th:string,Maitre_P:string,Date_modified:Date,old_permis:string , Code_Ecole:string){
        this.NumContrat = NumContrat;
        this.Cin = Cin;
        this.Nom = Nom;
        this.Prenom = Prenom;
        this.Sexe = Sexe;
        this.DateNaissance = DateNaissance;
        this.LieuNaissance = LieuNaissance;
        this.Adresse = Adresse;
        this.Ville = Ville;
        this.tarif = tarif;
        this.Email = Email;
        this.Telephone = Telephone;
        this.Vehicule = Vehicule;
        this.CategoriePermis = CategoriePermis;
        this.CapMoniteur = CapMoniteur;
        this.Montant = Montant;
        this.Credit = Credit;
        this.ImagePath = ImagePath;
        this.posted_by = posted_by ;
        this.Date_entrer = Date_entrer;
        this.DateTh1_Examen = DateTh1_Examen;
        this.DateTh2_Examen = DateTh2_Examen;
        this.DateP1_Examen = DateP1_Examen;
        this.DateP2_Examen = DateP2_Examen;
        this.DateCtrl = DateCtrl;
        this.Montant_total = Montant_total;
        this.status = status;
        this.Maitre_Th = Maitre_Th;
        this.Maitre_P = Maitre_P;
        this.Date_modified = Date_modified;
        this.old_permis = old_permis;
        this.Code_Ecole = Code_Ecole ;
        
    }
}