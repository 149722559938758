import {
  Component,
  OnInit,
  AfterContentInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { CandidatService } from "../candidats/candidat.service";
import { CandidatStorageService } from "../candidats/condidat-storage.service";
import { Subscription } from "rxjs";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { jqxChartComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxchart";
import { ChargesService } from "../charges/charges.service";
import { ChargesStorageService } from "../charges/charges-storage.service";
import { VehiculeService } from "../vehicules/vehicule.service";
import { VehiculeStorageService } from "../vehicules/Vehicule-storage.service";
import { CarburantStorageService } from "../vehicules/carburant/carburant-storage.service";
import { CarburantService } from "../vehicules/carburant/carburant.service";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { MoniteurStorageService } from "../monitors/Moniteur-storage.service";
import { MoniteurService } from "../monitors/moniteur.service";
import { ReclamationStorage } from "../reclamation/reclamation-storage.service";
import { ReclamationSerivce } from "../reclamation/reclamation.service";

@Component({
  selector: "app-statistic",
  templateUrl: "./statistic.component.html",
  styleUrls: ["./statistic.component.css"],
})
export class StatisticComponent implements OnInit, OnDestroy, AfterContentInit {
  @ViewChild("myChart", { static: false }) myChart: jqxChartComponent;

  Inscription_Details = [];
  Depense_Details = [];
  BackUp = [];
  opendetails: string;
  monthStr: string;

  CandidatChart: {
    month: string;
    Candidats: number;
    Reussis: number;
    Redoublants: number;
    quota: number;
    revenu: number;
  }[] = [
    {
      month: "Janvier",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Février",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Mars",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Avril",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Mai",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Juin",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Juillet",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Août",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Septembre",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Octobre",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Novembre",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
    {
      month: "Decembre",
      Candidats: 0,
      Reussis: 0,
      Redoublants: 0,
      quota: 0,
      revenu: 0,
    },
  ];

  ChargesChart: { month: string; montant: number }[] = [
    { month: "Janvier", montant: 0 },
    { month: "Février", montant: 0 },
    { month: "Mars", montant: 0 },
    { month: "Avril", montant: 0 },
    { month: "Mai", montant: 0 },
    { month: "Juin", montant: 0 },
    { month: "Juillet", montant: 0 },
    { month: "Août", montant: 0 },
    { month: "Septembre", montant: 0 },
    { month: "Octobre", montant: 0 },
    { month: "Novembre", montant: 0 },
    { month: "Decembre", montant: 0 },
  ];

  Inventaire: {
    month: string;
    inscription: number;
    quota: number;
    Apte: number;
    Inapte: number;
    depense: number;
    revenue: number;
  }[] = [
    {
      month: "Janvier",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Février",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Mars",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Avril",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Mai",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Juin",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Juillet",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Août",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Septembre",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Octobre",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Novembre",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Decembre",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
    {
      month: "Total",
      inscription: 0,
      quota: 0,
      Apte: 0,
      Inapte: 0,
      depense: 0,
      revenue: 0,
    },
  ];

  monthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre",
  ];

  Xgraph = [
    { month: "Janvier" },
    { month: "Février" },
    { month: "Mars" },
    { month: "Avril" },
    { month: "Mai" },
    { month: "Juin" },
    { month: "Juillet" },
    { month: "Août" },
    { month: "Septembre" },
    { month: "Octobre" },
    { month: "Novembre" },
    { month: "Decembre" },
  ];

  faLamp = fa.faExclamationTriangle;
  faInfo = fa.faInfoCircle;
  VehiculePerStats = [];
  listExamTomorrow_Th = [];
  listExamTomorrow_P = [];
  candidatPerYears = [];
  candidatPerMonth = [];
  revenuePerMonth: number = 0;
  revenuPerYear: number = 0;
  quotaPerYear: number = 0;
  CarburantData = [];
  success: number = 0;
  failed: number = 0;
  Profile ;
  chargePerYears: number = 0;
  chargePerMonth: number = 0;
  subscriptionC: Subscription;
  subscriptionCH: Subscription;
  subscriptionV: Subscription;
  visibility = false;
  source = {
    dataType: "array",
    localdata: [],
  };
  Max = 0;
  dataAdapterCanddiat: any;
  dataAdapterCharge: any;
  dataAdapterCarburant: any;
  DivProgress: string = "Dashboard";
  series: any = [];
  showBackUpDialog: boolean;
  fadetails = fa.faCalendarAlt;

  constructor(
    private candidatService: CandidatService,
    private candidatStorage: CandidatStorageService,
    private chargeService: ChargesService,
    private chargeStorage: ChargesStorageService,
    private vehiculeService: VehiculeService,
    private vehiculeStorage: VehiculeStorageService,
    private carburantStorage: CarburantStorageService,
    private carburantService: CarburantService,
    private toastr: ToastrService,
    private moniteurStorage: MoniteurStorageService,
    private moniteurService: MoniteurService,
    private ReclamationStorage: ReclamationStorage,
    private ReclamationService: ReclamationSerivce
  ) {}

  ngOnInit() {
    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));

    if (userData.email === environment.Manager) {
      this.visibility = true;
      this.Profile = {code:'All'}
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
       this.Profile = environment.Ecole.find(item => item.code === Current_Code);
       //this.Codes_Ecole.push(this.Profile.code)
       //this.Code_Ecole_edit = this.Profile.code
    }


  }

  Notification() {
    this.toastr.warning(
      '<div class="aaa"><ul class="list-group "><li class="list-group-item d-flex justify-content-between align-items-center">Théorique <span class="badge badge-pill">' +
        this.listExamTomorrow_Th.length +
        '</span></li><li class="list-group-item d-flex justify-content-between align-items-center">Pratique <span class="badge badge-pill">' +
        this.listExamTomorrow_P.length +
        "</span></li></ul></div>",
      "Examens Pour Demain",
      {
        disableTimeOut: true,
        timeOut: 0,
      }
    );
  }

  ngAfterContentInit() {
    this.getCandidatStat();
    this.getChargeStat();
    this.getCarburantStat();
    this.getVehiculeStat();
    this.getPointage();
    this.getListMoniteurs();
    this.getListReclamation();
    const DateBackUp = JSON.parse(localStorage.getItem("BackUpDate"));
    const VisibilityTimeout = 20000;
    // console.log(DateBackUp);
    if (!DateBackUp) {
      setTimeout(() => {
        this.showBackUpDialog = true;
      }, VisibilityTimeout);
    } else {
      var Difference_In_Time =
        new Date().getTime() - new Date(DateBackUp).getTime();
      var Difference_In_Days = Math.round(
        Difference_In_Time / (1000 * 3600 * 24)
      );
      //  console.log(Difference_In_Days);
      if (Difference_In_Days >= 7) {
        setTimeout(() => {
          this.showBackUpDialog = true;
        }, VisibilityTimeout);
      }
    }
  }

  onCloseModal() {
    this.showBackUpDialog = null;
    this.opendetails = null;
  }

  toggleWithGreeting(popover, greeting: string, language: string) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open({ greeting, language });
    }
  }

  ngOnDestroy() {
    this.subscriptionC.unsubscribe();
    this.subscriptionV.unsubscribe();
  }

  getListReclamation() {
    this.ReclamationStorage.GetReclamations().subscribe();
    return this.ReclamationService.SyncListReclamations.subscribe((Element) => {
      this.BackUp.push({ Name: "REC", Data: Element });
    });
  }

  getPointage() {
    this.candidatStorage.GetPointage().subscribe();
    return this.candidatService.SyncListPointage.subscribe((Element) => {
      this.BackUp.push({ Name: "PT", Data: Element });
    });
  }

  getListMoniteurs() {
    this.moniteurStorage.GetMoniteurs().subscribe();
    return this.moniteurService.SyncListMoniteur.subscribe((Element) => {
      this.BackUp.push({ Name: "MNT", Data: Element });
    });
  }

  getCandidatStat() {
    this.candidatStorage.GetCandidats().subscribe();
    this.subscriptionC = this.candidatService.SyncListCondidats.subscribe(
      (Element) => {
        this.BackUp.push({ Name: "CDT", Data: Element });
        const CurrentYear = new Date().getFullYear();
        const CurrentMonth = new Date().getMonth() + 1;
        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        var Items = [];
        if( this.Profile.code != 'All' ){
          for(var i=0;i<Element.length;i++){
            if(Element[i].Code_Ecole === this.Profile.code ){
              Items.push(Element[i]);
            }
          }
        }else{
          Items = Element
        }        
        Items.filter((ob) => {
          let obDate = new Date(ob.Date_entrer).getFullYear();
          let obMonth = new Date(ob.Date_entrer).getMonth() + 1;
          let date_Exam_th = "";
          let date_Exam_P = "";
          let obQuota = new Date(ob.DateCtrl).getMonth() + 1;

          if (ob.DateTh2_Examen) {
            date_Exam_th = new Date(ob.DateTh2_Examen).toLocaleDateString();
          } else {
            date_Exam_th = new Date(ob.DateTh1_Examen).toLocaleDateString();
          }

          if (ob.DateP2_Examen) {
            date_Exam_P = new Date(ob.DateP2_Examen).toLocaleDateString();
          } else {
            date_Exam_P = new Date(ob.DateP1_Examen).toLocaleDateString();
          }

          if (tomorrow.toLocaleDateString() == date_Exam_th) {
            this.listExamTomorrow_Th.push(ob);
          }
          if (tomorrow.toLocaleDateString() == date_Exam_P) {
            this.listExamTomorrow_P.push(ob);
          }

          if (obDate === CurrentYear) {
            this.Inscription_Details.push({ Month: obMonth, Data: ob });
            this.candidatPerYears.push(ob.Cin);
            this.revenuPerYear += ob.Montant_total;
            if (obQuota) {
              this.quotaPerYear += 1;
            }
            this.CandidatChart.filter((Row) => {
              if (ob.Credit) {
                ob.Credit.filter((R) => {
                  let creditDate = new Date(R.date).getMonth() + 1;
                  if (Row.month === this.monthNames[creditDate - 1]) {
                    Row.revenu += R.Amount;
                  }
                });
              }
              if (Row.month === this.monthNames[obMonth - 1]) {
                Row.Candidats += 1;
                if (ob.status === "Apte") {
                  Row.Reussis += 1;
                }
                if (ob.status === "Inapte") {
                  Row.Redoublants += 1;
                }
              }
            });
          }
          if (obDate === CurrentYear && obMonth === CurrentMonth) {
            this.candidatPerMonth.push(ob.Cin);
            this.revenuePerMonth += ob.Montant_total;
          }

          if (ob.status === "Apte") {
            this.success += 1;
          }
          if (ob.status === "Inapte") {
            this.failed += 1;
          }
        });

        this.source.localdata = this.CandidatChart;
        this.dataAdapterCanddiat = new jqx.dataAdapter(this.source, {
          async: false,
          autoBind: true,
          loadError: (xhr, status, error) => {
            alert("Error loading " + this.source.localdata + " : " + error);
          },
        });

        var TotalApt: number = 0;
        this.CandidatChart.filter((Row) => {
          this.Inventaire.filter((Row_Inv) => {
            if (Row.month === Row_Inv.month) {
              Row_Inv.Apte = Row.Reussis;
              Row_Inv.Inapte = Row.Redoublants;
              Row_Inv.inscription = Row.Candidats;
              Row_Inv.quota = Row.quota;
              Row_Inv.revenue = Row.revenu;
            }
            if (Row_Inv.month === "Total") {
              Row_Inv.Apte = this.success;
              Row_Inv.Inapte = this.failed;
              Row_Inv.inscription = this.candidatPerYears.length;
              Row_Inv.quota = this.quotaPerYear;
              Row_Inv.revenue = this.revenuPerYear;
            }
          });
        });
        this.Notification();
      }
    );
  }

  getMaxId() {
    let M: number = 0;
    let R: number = 0;
    let S: number = 0;
    this.CandidatChart.filter((Row) => {
      if (Row.Candidats > M) {
        M = Row.Candidats;
      }
      if (Row.Redoublants > R) {
        R = Row.Redoublants;
      }
      if (Row.Reussis > S) {
        S = Row.Reussis;
      }
    });
    return Math.max(M, R, S);
  }

  getVehiculeStat() {
    this.vehiculeStorage.GetVehicules().subscribe();
    this.subscriptionV = this.vehiculeService.SyncListVehicule.subscribe(
      (Element) => {
        this.BackUp.push({ Name: "VEC", Data: Element });

        var Items = [];
        if( this.Profile.code != 'All' ){
          for(var i=0;i<Element.length;i++){
            if(Element[i].Code_Ecole === this.Profile.code ){
              Items.push(Element[i]);
            }
          }
        }else{
          Items = Element
        }  

        this.VehiculePerStats = Items;

        this.VehiculePerStats.filter((ob) => {
          let assuranceDate = new Date(ob.D_assurance);
          let visiteDate = new Date(ob.D_visite);
          let CurrentDate = new Date();

          let km_prochaine = ob.km_prochaine;
          let km_dernier = ob.km_dernier;
          let km_actuel = ob.km_actuel;

          let tmp =
            ((km_actuel - km_dernier) * 100) / (km_prochaine - km_dernier);
          // console.log(tmp);

          var Difference_Assurance: number =
            CurrentDate.getTime() - assuranceDate.getTime();
          ob["Progress_Assurance"] =
            Math.round(
              ((Difference_Assurance / (1000 * 3600 * 24)) * 100) / 365
            ) > 100
              ? 100
              : Math.round(
                  ((Difference_Assurance / (1000 * 3600 * 24)) * 100) / 365
                );

          var Difference_visite: number =
            CurrentDate.getTime() - visiteDate.getTime();
          ob["Progress_Visite"] =
            Math.round(((Difference_visite / (1000 * 3600 * 24)) * 100) / 365) >
            100
              ? 100
              : Math.round(
                  ((Difference_visite / (1000 * 3600 * 24)) * 100) / 365
                );

          ob["Progress_Vidange"] =
            Math.round(
              ((km_actuel - km_dernier) * 100) / (km_prochaine - km_dernier)
            ) > 100
              ? 100
              : Math.round(
                  ((km_actuel - km_dernier) * 100) / (km_prochaine - km_dernier)
                );
        });
      }
    );
  }

  getCarburantStat() {
    let UniqVehicule = [];
    //let VehiculesData = [];
    this.carburantStorage.GetCarburants().subscribe();
    this.subscriptionCH = this.carburantService.SyncListCarburant.subscribe(
      (Element) => {
        this.BackUp.push({ Name: "CARB", Data: Element });
        const CurrentYear = new Date().getFullYear();
        UniqVehicule = [...new Set(Element.map((a) => a.Matricule))];
        UniqVehicule.filter((VehiculeElement) => {
          let MatriculeArr: {
            Matricule: string;
            month: string;
            value: number;
          }[] = [];
          // console.log('Matricule de Vehicule : '+VehiculeElement);
          Element.filter((ob) => {
            if (ob.Matricule === VehiculeElement) {
              let obDate = new Date(ob.Date_enter).getFullYear();
              let obMonth = new Date(ob.Date_enter).getMonth();
              if (obDate === CurrentYear) {
                for (var i = 0; i < this.monthNames.length; i++) {
                  let leng: number;
                  leng = MatriculeArr.findIndex(
                    (elmt) =>
                      elmt.Matricule === ob.Matricule &&
                      elmt.month === this.monthNames[i]
                  );
                  if (leng === -1) {
                    if (obMonth === i) {
                      MatriculeArr.push({
                        Matricule: ob.Matricule,
                        month: this.monthNames[i],
                        value: ob.Montant,
                      });
                    } else {
                      MatriculeArr.push({
                        Matricule: ob.Matricule,
                        month: this.monthNames[i],
                        value: 0,
                      });
                    }
                  } else {
                    let el = MatriculeArr[leng];
                    if (obMonth === i) {
                      el.value += ob.Montant;
                      MatriculeArr[leng] = el;
                    }
                  }
                }
              }
            }
          });
          //VehiculesData.push([...MatriculeArr])
          MatriculeArr.map((element) => {
            let ind = this.Xgraph.findIndex(
              (elmt) => elmt.month === element.month
            );
            // console.log("index :: ",ind);
            this.Xgraph[ind][element.Matricule] = element.value;
          });
        });
        //console.log(this.Xgraph);
        this.source.localdata = this.Xgraph;
        //souhail
        //console.log(this.source.localdata);

        UniqVehicule.map((car) => {
          // console.log(car);
          let obj = {
            dataField: car,
            symbolType: "square",
            labels: {
              visible: true,
              balabelsckgroundColor: "#FEFEFE",
              backgroundOpacity: 0.2,
              borderColor: "#7FC4EF",
              borderOpacity: 0.7,
              padding: { left: 5, right: 5, top: 0, bottom: 0 },
            },
          };
          this.series.push(obj);
        });
        // console.log(this.series);
        this.dataAdapterCarburant = new jqx.dataAdapter(this.source, {
          async: false,
          autoBind: true,
          loadError: (xhr, status, error) => {
            alert("Error loading " + this.source.localdata + " : " + error);
          },
        });
      }
    );
  }

  getChargeStat() {
    this.chargeStorage.GetCharges().subscribe();
    this.subscriptionCH = this.chargeService.SyncListCharges.subscribe(
      (Element) => {
        this.BackUp.push({ Name: "CH", Data: Element });
        const CurrentYear = new Date().getFullYear();
        const CurrentMonth = new Date().getMonth() + 1;
        // console.log(Element);
        Element.filter((ob) => {
          let obDate = new Date(ob.Date_enter).getFullYear();
          let obMonth = new Date(ob.Date_enter).getMonth() + 1;

          //            console.log(ob)

          if (obDate === CurrentYear) {
            this.chargePerYears += ob.Montant;
            this.Depense_Details.push({ Month: obMonth, Data: ob });
            this.ChargesChart.filter((Row) => {
              if (Row.month === this.monthNames[obMonth - 1]) {
                // Row.Candidats += ob.Montant;
                Row.montant += ob.Montant;
                this.Inventaire.filter((Row_Inv) => {
                  if (Row.month === Row_Inv.month) {
                    Row_Inv.depense += ob.Montant;
                  }
                  if (Row_Inv.month === "Total") {
                    Row_Inv.depense = this.chargePerYears;
                  }
                });
              }
            });
          }
          if (obDate === CurrentYear && obMonth === CurrentMonth) {
            this.chargePerMonth += ob.Montant;
          }
        });
        // this.Max = this.getMaxId();

        this.source.localdata = this.ChargesChart;
        this.dataAdapterCharge = new jqx.dataAdapter(this.source, {
          async: false,
          autoBind: true,
          loadError: (xhr, status, error) => {
            alert("Error loading " + this.source.localdata + " : " + error);
          },
        });
      }
    );
  }

  padding: any = { left: 10, top: 10, right: 15, bottom: 10 };
  titlePadding: any = { left: 90, top: 0, right: 0, bottom: 10 };

  getWidth(): any {
    /* if (document.body.offsetWidth < 850) {
    return document.body.offsetWidth-100;
  }
  //return '50%';
  */
    return document.body.offsetWidth - 62;
  }

  xAxis: any = {
    dataField: "month",
    unitInterval: 1,
    tickMarks: { visible: true, interval: 1 },
    gridLinesInterval: { visible: true, interval: 1 },
    valuesOnTicks: false,
    padding: { bottom: 10 },
  };

  valueAxis_1: any = {
    minValue: 0,
    maxValue: "auto",
    title: { text: "" },
    labels: { horizontalAlignment: "right" },
  };

  valueAxis: any = {
    unitInterval: 10,
    minValue: 0,
    maxValue: "auto",
    title: { text: "" },
    labels: { horizontalAlignment: "right" },
  };

  valueAxis_2: any = {
    title: { text: "" },
    labels: { horizontalAlignment: "right" },
  };

  seriesGroups_1: any[] = [
    {
      type: "line",
      series: [
        {
          dataField: "Candidats",
          symbolType: "square",
          color: "#e2ab07",
          labels: {
            visible: true,
            backgroundColor: "#FEFEFE",
            backgroundOpacity: 0.2,
            borderColor: "#7FC4EF",
            borderOpacity: 0.7,
            padding: { left: 5, right: 5, top: 0, bottom: 0 },
          },
        },
        {
          dataField: "Reussis",
          symbolType: "square",
          color: "#28a745",
          labels: {
            visible: true,
            backgroundColor: "#FEFEFE",
            backgroundOpacity: 0.2,
            borderColor: "#7FC4EF",
            borderOpacity: 0.7,
            padding: { left: 5, right: 5, top: 0, bottom: 0 },
          },
        },
        {
          dataField: "Redoublants",
          symbolType: "square",
          color: "#dc3545",
          labels: {
            visible: true,
            backgroundColor: "#FEFEFE",
            backgroundOpacity: 0.2,
            borderColor: "#7FC4EF",
            borderOpacity: 0.7,
            padding: { left: 5, right: 5, top: 0, bottom: 0 },
          },
        },
      ],
    },
  ];

  seriesGroups_2: any[] = [
    {
      type: "line",
      series: [
        {
          dataField: "montant",
          symbolType: "square",
          color: "#e2ab07",
          labels: {
            visible: true,
            backgroundColor: "#FEFEFE",
            backgroundOpacity: 0.2,
            borderColor: "#7FC4EF",
            borderOpacity: 0.7,
            padding: { left: 5, right: 5, top: 0, bottom: 0 },
          },
        },
      ],
    },
  ];

  seriesGroups_3: any[] = [
    {
      type: "line",
      series: this.series,
    },
  ];
}
