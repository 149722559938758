import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { CandidatStorageService } from 'src/app/candidats/condidat-storage.service';
import { Pointage } from './pointage.model';
import { AuthService } from 'src/app/authentification/auth.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  faCheck = fa.faCheck;
  isLoading = false;
  @Input() ParamId: string;
  @Output() close = new EventEmitter<void>();
  faclose = fa.faTimesCircle;
  postedBy:string;
  
  datePickerConfig: Partial<BsDatepickerConfig>;
  heures = [
    '00','01','02','03','04','05','06','07','08','09','10','11',
    '13','14','15','16','17','18','19','20','21','22','23'
  ];
  minutes = [
    '00','15','30','45'
   ];
  
  constructor(
    private candidatstorage : CandidatStorageService,
    private authService : AuthService

  ) { 
    this.datePickerConfig = Object.assign({}
      ,{
        containerClass: 'theme-default',
        showWeekNumbers: false ,
        adaptivePosition: true,
        rangeInputFormat: 'DD-MM-YYYY',
        isAnimated: true,
    });
  }

  ngOnInit() {
  }

  onClose(){
    this.close.emit();
  }

  onSubmit(form: NgForm){
    this.postedBy = this.authService.getSessionLogged();
    //console.log(form.value);
    this.isLoading = true;
   // console.log(this.ParamId);
    const newPointage = new Pointage(this.ParamId,form.value.DateSeance,form.value.heures,form.value.minutes,this.postedBy,new Date());
    this.candidatstorage.SavePointage(newPointage)
    .subscribe( response => {
      this.isLoading = false;
      this.onClose();
    });
    this.candidatstorage.SavePointageUser(newPointage).subscribe();
  }

}
