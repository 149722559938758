import { map,tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReclamationSerivce } from './reclamation.service';
@Injectable({providedIn:'root'})
export class ReclamationStorage {
    constructor(
        private http: HttpClient,
        private ReclamationService: ReclamationSerivce
    ){}
    
    GetReclamations() {
      return this.http
        .get<any>(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/REC.json',
        )
        .pipe(
          map(response => {
              const postArray = [];
              for(const key in response){
                  if( response.hasOwnProperty(key) ){
                  postArray.push({...response[key],id: key});
                  }
              }
              return postArray;
          }),
          tap( Res => {
              this.ReclamationService.setListOfReclamations(Res);
          })
        );
    }

    UpdateStatusReclamation(key){
        return this.http.patch(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/REC/'+key+'.json'
            ,{ 
                status: 1,
            } 
        )
    }

}