import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { NgForm } from '@angular/forms';
import { ReclamationStorage } from '../reclamation-storage.service';

@Component({
  selector: 'app-view-reclamations',
  templateUrl: './view-reclamations.component.html',
  styleUrls: ['./view-reclamations.component.css']
})
export class ViewReclamationsComponent implements OnInit {

  isLoading = false;
  @Output() close = new EventEmitter<void>();
  @Input() Paramkey = new EventEmitter<void>();
  @Input() ParamFullName = new EventEmitter<void>();
  @Input() ParamTitle = new EventEmitter<void>();
  @Input() ParamDate = new EventEmitter<void>();
  @Input() ParamMessage = new EventEmitter<void>();
  
  faclose = fa.faTimesCircle;
  facheck = fa.faCheck;
  constructor(
    private ReclamationStorage : ReclamationStorage
  ) { }
  
  
  ngOnInit() {
  }

  onClose(){
    this.close.emit();
  }

  onSubmit(form: NgForm){
    this.isLoading = true;
    if(this.Paramkey){
      this.ReclamationStorage.UpdateStatusReclamation(this.Paramkey).subscribe(
        res => {
          this.isLoading = false;
          this.onClose();
        }
      );
    }
  }

}
