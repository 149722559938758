import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AuthentificationComponent } from './authentification/authentification.component';
import { HeaderComponent } from './header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {ProgressBarModule} from "angular-progress-bar"
import { CandidatsComponent } from './candidats/candidats.component';
import { MonitorsComponent } from './monitors/monitors.component';
import { AppRoutingModule } from './app-routing.module';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ListVehiculesComponent } from './vehicules/list-vehicules/list-vehicules.component';
import { NewVehiculesComponent } from './vehicules/new-vehicules/new-vehicules.component';
import { VehiculesComponent } from './vehicules/vehicules.component';
import { NewMonitorsComponent } from './monitors/new-monitors/new-monitors.component';
import { ListMonitorsComponent } from './monitors/list-monitors/list-monitors.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { ListCandidatsComponent } from './candidats/list-candidats/list-candidats.component';
import { NewCandidatComponent } from './candidats/new-candidat/new-candidat.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { AuthInterceptorService } from './authentification/auth-interceptor.service';
import { ChargesComponent } from './charges/charges.component';
import { ListChargesComponent } from './charges/list-charges/list-charges.component';
import { NewChargesComponent } from './charges/new-charges/new-charges.component';
import { StatisticComponent } from './statistic/statistic.component';
import { jqxChartComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxchart';
import { ModalComponent } from './shared/modal/modal.component';
import { ConfirmModalComponent } from './shared/confirm-modal/confirm-modal.component';
import {NgxPrintModule} from 'ngx-print';
import { ReclamationComponent } from './reclamation/reclamation.component';
import { ViewReclamationsComponent } from './reclamation/view-reclamations/view-reclamations.component';
import { ListCarburantComponent } from './vehicules/carburant/list-carburant/list-carburant.component';
import { AngularFireModule } from '@angular/fire/'
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ToastrModule } from 'ngx-toastr';
import { CreditModalComponent } from './shared/credit-modal/credit-modal.component';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './footer/footer.component';
import { BackUpComponent } from './shared/back-up/back-up.component';
import { PointageComponent } from './candidats/pointage/pointage.component';
import { InventaireDetailsComponent } from './shared/inventaire-details/inventaire-details.component';
import {WebcamModule} from 'ngx-webcam';
import { TestComponent } from './shared/test/test.component';
import { WebcamComponent } from './shared/webcam/webcam.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthentificationComponent,
    HeaderComponent, 
    CandidatsComponent,
    MonitorsComponent,
    jqxDataTableComponent,
    jqxChartComponent,
    ListVehiculesComponent,
    NewVehiculesComponent,
    VehiculesComponent,
    NewMonitorsComponent,
    ListMonitorsComponent,
    PageNotFoundComponent,
    ListCandidatsComponent,
    NewCandidatComponent,
    LoadingSpinnerComponent,
    ChargesComponent,
    ListChargesComponent,
    NewChargesComponent,
    StatisticComponent,
    ModalComponent,
    ConfirmModalComponent,
    ReclamationComponent,
    ViewReclamationsComponent,
    ListCarburantComponent,
    CreditModalComponent,
    FooterComponent,
    BackUpComponent,
    PointageComponent,
    InventaireDetailsComponent,
    TestComponent,
    WebcamComponent
  ],
  imports: [
    BrowserModule,
    WebcamModule,
    FormsModule,
    HttpClientModule,
    ProgressBarModule,
    NgbModule,
    FontAwesomeModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-top-right',
        closeButton : true,
        enableHtml : true,
        maxOpened : 1
      //  progressBar: true,
      //  progressAnimation : 'decreasing',
      }
    ),
    BrowserAnimationsModule,
    NgxPrintModule,
    AngularFireModule.initializeApp({
      apiKey: environment.firebaseAPIKey,
      authDomain: environment.firebaseProjectId+".firebaseapp.com",
      storageBucket: environment.firebaseProjectId+".appspot.com",
      projectId: environment.firebaseProjectId,
    }),
    AngularFireStorageModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
