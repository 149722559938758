import { map,tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Carburant } from './carburant.model';
import { CarburantService } from './carburant.service';

@Injectable({providedIn:'root'})
export class CarburantStorageService {
    constructor(
        private http: HttpClient,
        private CarburantService: CarburantService
    ){}
    

    SaveCarburant(Carburant: Carburant){
      return this.http
      .post(
          'https://'+environment.firebaseProjectId+'.firebaseio.com/CARB.json',
          Carburant
          );
    }
          
    GetCarburants() {
        return this.http
          .get<Carburant>(
            'https://'+environment.firebaseProjectId+'.firebaseio.com/CARB.json',
          )
          .pipe(
            map(response => {
                const postArray = [];
                for(const key in response){
                    if( response.hasOwnProperty(key) ){
                    postArray.push({...response[key],id: key});
                    }
                }
                return postArray;
            }),
            tap( Values => {
                this.CarburantService.setListOfCarburant(Values);
            })
          );
      }

}