import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate{

constructor(private AuthService: AuthService, private router: Router){}

    canActivate( route: ActivatedRouteSnapshot, state : RouterStateSnapshot ): boolean | Promise<boolean | UrlTree> | Observable<boolean | UrlTree>  {
         return this.AuthService.user.pipe(
            take(1),
            map(user =>{
                const isAuth = !!user;
                if(isAuth){
                    /*if(user.email == 'test@test.com'){
                        return this.router.createUrlTree(['/dddddd']);
                    }else{
                        return true;
                    }*/
                    return true;
                    
                }else{
                    return this.router.createUrlTree(['/auth']);
                }
            })
         );
    }

}