import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReclamationSerivce{
    constructor(
    ){}
;
    private ListReclamations = [];
    SyncListReclamations = new Subject<any>();
    
    getListOfReclamations(){
        this.SyncListReclamations.next(this.ListReclamations.slice());
        return this.ListReclamations.slice();
    }

    setListOfReclamations(Value) {
        this.ListReclamations = Value ;
        this.SyncListReclamations.next(this.ListReclamations.slice());
    }

}