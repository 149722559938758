import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthResponsData, AuthService } from './auth.service';
import { Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.css']
})
export class AuthentificationComponent implements OnInit {

  facheck = fa.faDoorOpen;
  faError = fa.faExclamationTriangle;
  isLoginMode = true;
  isLoading = false;
  error:string = null;


  constructor( private AuthService: AuthService , private router: Router ) { }

  ngOnInit() {
  }

  onSubmit(form: NgForm){

    if(!form.valid){
      return;
    }
    this.error = "";
    const email = form.value.email+"@bouharat.com";
    const password = form.value.password;
    let AuthObs:Observable<AuthResponsData>;
    this.isLoading = true;
   
    if( (email !== environment.Manager) && (email !== environment.Admin)  && (email !== environment.Ecole1)  && (email !== environment.Ecole2)  && (email !== environment.Ecole3) ){
      this.error = "1.0 Désolé, Accès refusé";
      this.isLoading = false;
      return;
    }
    
    if(this.isLoginMode){
      if( (email !== environment.Manager) || (email !== environment.Admin) || (email !== environment.Ecole1) || (email !== environment.Ecole2) || (email !== environment.Ecole3) ){
          AuthObs =  this.AuthService.login(email,password);
      }
      else{
        this.error = "1.1 Désolé, Accès refusé";
        this.isLoading = false;
      }
    }
    /*
    else{
      AuthObs =  this.AuthService.signUp(email,password);
    }
    */
    AuthObs.subscribe(
      responseData => {
        if( (email !== environment.Manager) && (email !== environment.Admin) && (email !== environment.Ecole1) && (email !== environment.Ecole2) && (email !== environment.Ecole3) ){
          this.error = "1.2 Désolé, Accès refusé";
          this.isLoading = false;
          localStorage.removeItem('UserSystem');
          this.router.navigate(['/auth']);    
          return;   
        }else{
          this.router.navigate(['/Dashboard']);
        }
        this.isLoading = false;
      },errorRes => {
        this.error = errorRes;
        this.isLoading = false;
      }
    );

   form.reset();

  }

}
