import { Component, OnInit, AfterContentInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { VehiculeStorageService } from '../Vehicule-storage.service';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '@angular/common';
import { VehiculeService } from '../vehicule.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-vehicules',
  templateUrl: './list-vehicules.component.html',
  styleUrls: ['./list-vehicules.component.css']
})
export class ListVehiculesComponent implements OnInit ,OnDestroy , AfterContentInit {

  @ViewChild('myDataTable',{static:false}) myDataTable: jqxDataTableComponent;
  faPrint = fa.faPrint;
  faExcel = fa.faFileExcel;
  faPdf = fa.faFilePdf;
  faEdit = fa.faEdit;
  StatusEditable = false;
  Rowselected:string ;
  ListVehicules = [] ;
  Profile ;
  dataAdapter: any ;
  source = {
    dataType: 'array',
    //dataType: 'json',
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'Code_Ecole', type: 'string' },
      { name: 'Marque', type: 'string' },
      { name: 'Modele', type: 'string' },
      { name: 'Matricule', type: 'string' },
      { name: 'Categorie', type: 'string' },
      { name: 'Date_enter', type: 'date' },
      { name: 'D_assurance', type: 'date' },
      { name: 'D_visite', type: 'date' },
      { name: 'km_prochaine', type: 'number' },
      { name: 'km_actuel', type: 'number' },
      { name: 'km_dernier', type: 'number' },
    ],
    id: 'id',
    localdata: this.ListVehicules
    //url: '../assets/beverages.txt'
  };
  subscription :Subscription;
  constructor(
    private vehiculeStorage: VehiculeStorageService,
    private vehiculeService: VehiculeService
  ) { }

  ngOnInit() {
    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));
    
    if (userData.email === environment.Manager) {
      this.Profile = {code:'All'}
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
       this.Profile = environment.Ecole.find(item => item.code === Current_Code);
    }

  }

  ngAfterContentInit(){
    this.getListVehicules();
  }
  

  getListVehicules(){
    this.vehiculeStorage.GetVehicules().subscribe();
    this.ListVehicules = this.vehiculeService.getListOfVehicules();
    this.subscription =  this.vehiculeService.SyncListVehicule.subscribe(
       ( Element  ) => {
        var Items = [];
        if( this.Profile.code != 'All' ){
          for(var i=0;i<Element.length;i++){
            if(Element[i].Code_Ecole === this.Profile.code ){
              Items.push(Element[i]);
            }
          }
        }else{
          Items = Element
        } 
        this.ListVehicules = Items;
        //this.ListVehicules =   Element;
        this.source.localdata = this.ListVehicules;
       //console.log(this.source.localdata )
       function sortFunction(a,b){
        var dateA = new Date(a.Date_entrer).getTime();
        var dateB = new Date(b.Date_entrer).getTime();
        return dateA > dateB ? -1 : 1;  
        };
        this.ListVehicules.sort(sortFunction);​
        this.dataAdapter = new jqx.dataAdapter(this.source);
      }) 
  }  
  
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '100%';
    }
      return '100%';
    }
  
    exportSettings: any = { columnsHeader: true, hiddenColumns: false, characterSet: null, recordsInView: true, fileName: "myDataTable"};
  
    columns: any[] =
    [
      { text: 'Ecole', dataField: 'Code_Ecole', width: '10.5%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
      { text: 'Marque', dataField: 'Marque', width: '10.5%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
      { text: 'Modele', dataField: 'Modele', width: '10.5%' ,cellsalign: 'center' ,align: 'center' },
      { text: 'Matricule', dataField: 'Matricule', width: '10.5%' ,cellsalign: 'center' ,align: 'center'},
      { text: 'Categorie', dataField: 'Categorie', width: '7%' ,cellsalign: 'center' ,align: 'center'},
      { text: 'Date d\'assurance', dataField: 'D_assurance', width: '10.5%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
      { text: 'Dernière Vidange', dataField: 'km_dernier', width: '10.5%' ,cellsalign: 'center' , align: 'center' },
      { text: 'Actuel Km', dataField: 'km_actuel', width: '8%' ,cellsalign: 'center' , align: 'center' },
      { text: 'Prochaine Vidange', dataField: 'km_prochaine', width: '10.5%' ,cellsalign: 'center' , align: 'center' },
      { text: 'Date de visite technique', dataField: 'D_visite', width: '12.5%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
      { text: 'Date d\'entrer', dataField: 'Date_enter', width: '9.3  %' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' }
 ];
  
    onRowSelect(event: any): void {
      let args = event.args;
      let rowIndex = args.index;
      //console.log(args);
      this.Rowselected = args.key;
      this.StatusEditable = true;
      
    }
  
    printBtnOnClick(): void {
      const DateNow = formatDate(new Date(), 'yyyy/MM/dd', 'en');
      let gridContent = this.myDataTable.exportData('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
          document = newWindow.document.open(),
          pageContent =
              '<!DOCTYPE html>' +
              '<html>' +
              '<head>' +
              '<meta charset="utf-8" />' +
              '<title>Auto Ecole Bouharat '+DateNow+'</title>' +
              '</head>' +
              '<body>' + gridContent + '</body></html>';
      document.write(pageContent);
      document.close();
      newWindow.print();
    };
  
    excelExport(): void {
        this.myDataTable.exportData('xls');
    };
  
    pdfExport(): void {
        this.myDataTable.exportData('pdf');
    };
  
  }