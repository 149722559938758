import { Component, OnInit, AfterContentInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MoniteurStorageService } from '../Moniteur-storage.service';
import { MoniteurService } from '../moniteur.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-monitors',
  templateUrl: './list-monitors.component.html',
  styleUrls: ['./list-monitors.component.css']
})
export class ListMonitorsComponent implements OnInit, OnDestroy , AfterContentInit {
  
  @ViewChild('myDataTable',{static:false}) myDataTable: jqxDataTableComponent;
  faPrint = fa.faPrint;
  faExcel = fa.faFileExcel;
  faPdf = fa.faFilePdf;
  faEdit = fa.faEdit;
  StatusEditable = false;
  Rowselected:string ;
  Profile;
  ListMoniteurs = [] ;
  dataAdapter: any ;
  source = {
    dataType: 'array',
    //dataType: 'json',
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'ImagePath', type: 'image' },
      { name: 'Code_Ecole', type: 'string' },
      { name: 'Nom', type: 'string' },
      { name: 'Prenom', type: 'string' },
      { name: 'Cin', type: 'string' },
      { name: 'CAP', type: 'string' },
      { name: 'Categorie', type: 'string' },
      { name: 'PermisId', type: 'string' },
      { name: 'Date_enter', type: 'date' }
    ],
    id: 'id',
    localdata: this.ListMoniteurs
  };
  subscription :Subscription;
  constructor(
    private moniteurStorage: MoniteurStorageService ,
    private moniteurService: MoniteurService
  ) { }

  ngOnInit() {
    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));

    if (userData.email === environment.Manager) {
      this.Profile = {code:'All'}
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
       this.Profile = environment.Ecole.find(item => item.code === Current_Code);
    }
  }

  ngAfterContentInit(){
    this.getListMoniteurs();
  }

  getListMoniteurs(){
    this.moniteurStorage.GetMoniteurs().subscribe();
    this.ListMoniteurs = this.moniteurService.getListOfMoniteurs();
    this.subscription =  this.moniteurService.SyncListMoniteur.subscribe(
       ( Element  ) => {
        //console.log(Element);

        var Items = [];
        if( this.Profile.code != 'All' ){
          for(var i=0;i<Element.length;i++){
            if(Element[i].Code_Ecole === this.Profile.code ){
              Items.push(Element[i]);
            }
          }
        }else{
          Items = Element
        } 

        this.ListMoniteurs =   Items;
        this.source.localdata = this.ListMoniteurs;
        //console.log(this.source.localdata);
        function sortFunction(a,b){
          var dateA = new Date(a.Date_entrer).getTime();
          var dateB = new Date(b.Date_entrer).getTime();
          return dateA > dateB ? -1 : 1;  
        };
        this.ListMoniteurs.sort(sortFunction);​
        this.dataAdapter = new jqx.dataAdapter(this.source);
      }) 
  }  
  
  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '100%';
    }
      return '100%';
    }
  
  //dataAdapter: any = new jqx.dataAdapter(this.source);

  exportSettings: any = { columnsHeader: true, hiddenColumns: false, characterSet: null, recordsInView: true, fileName: "myDataTable"};
  

  imagerenderer = function (row, datafield, value) {
    return '<img class="imgPath" height="60" width="60" src="'+ value +'"/>';
  }

  columns: any[] =
  [
    { text: '', dataField: 'ImagePath', width: '70px' ,pinned: true , cellsalign: 'center' ,align: 'center' ,cellsrenderer: this.imagerenderer },
    { text: 'Ecole', dataField: 'Code_Ecole', width: '14.2%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'Nom', dataField: 'Nom', width: '14.2%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'Prenom', dataField: 'Prenom', width: '14.2%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'CIN', dataField: 'Cin', width: '16%' ,cellsalign: 'center' ,align: 'center' },
    { text: 'Permis', dataField: 'PermisId', width: '12%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'CAP', dataField: 'CAP', width: '12%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Categorie', dataField: 'Categorie', width: '12%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Date d\'entrer', dataField: 'Date_enter', width: '14.5%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' }
  ];

  onRowSelect(event: any): void {
    let args = event.args;
    let rowIndex = args.index;
    //console.log(args);
    this.Rowselected = args.key;
    this.StatusEditable = true;
    
  }

  printBtnOnClick(): void {
    const DateNow = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    let gridContent = this.myDataTable.exportData('html');
    let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>Auto Ecole Bouharat '+DateNow+'</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  };

  excelExport(): void {
      this.myDataTable.exportData('xls');
  };

  pdfExport(): void {
      this.myDataTable.exportData('pdf');
  };

}