import { Component, OnInit, AfterContentInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CandidatService } from '../candidat.service';
import { CandidatStorageService } from '../condidat-storage.service';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import { formatDate } from '@angular/common';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-candidats',
  templateUrl: './list-candidats.component.html',
  styleUrls: ['./list-candidats.component.css']
})
export class ListCandidatsComponent implements OnInit , AfterContentInit {

  @ViewChild('myDataTable',{static:false}) myDataTable: jqxDataTableComponent;
  ListCandidats = [] ;
  faPrint = fa.faPrint;
  faExcel = fa.faFileExcel;
  faPdf = fa.faFilePdf;
  faEdit = fa.faEdit;
  StatusEditable = false;
  Profile;
  Rowselected:string ;
  dataAdapter: any ;
  source = {
    dataType: 'array',
    //dataType: 'json',
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'ImagePath', type: 'image' },
      { name: 'Code_Ecole', type: 'string' },
      { name: 'NumContrat', type: 'string' },
      { name: 'Nom', type: 'string' },
      { name: 'Prenom', type: 'string' },
      { name: 'Cin', type: 'string' },
      // { name: 'Sexe', type: 'string' },
      // { name: 'DateNaissance', type: 'date' },
      // { name: 'LieuNaissance', type: 'string' },
      // { name: 'Adresse', type: 'string' },
      // { name: 'Ville', type: 'string' },
      // { name: 'Telephone', type: 'string' },
      // { name: 'Email', type: 'string' },
      { name: 'CategoriePermis', type: 'string' },
      { name: 'tarif', type: 'int' },
      { name: 'Montant_total', type: 'int' },
      { name: 'Date_entrer', type: 'date' },
      { name: 'DateCtrl', type: 'date' },
      { name: 'DateTh1_Examen', type: 'date' },
      { name: 'DateTh2_Examen', type: 'date' },
      { name: 'DateP1_Examen', type: 'date' },
      { name: 'DateP2_Examen', type: 'date' },
      { name: 'status', type: 'string' },
      { name: 'Vehicule', type: 'string' }
    ],
    id: 'id',
    localdata: this.ListCandidats,
    sortcolumn: 'Date_entrer',
    sortdirection: 'asc'
  };
  subscription :Subscription;
  constructor(
    private candidatService: CandidatService ,
    private candidatStorage: CandidatStorageService
  ) { }

  ngOnInit() {
    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));

    if (userData.email === environment.Manager) {
      this.Profile = {code:'All'}
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
       this.Profile = environment.Ecole.find(item => item.code === Current_Code);
    }

  }

  ngAfterContentInit(){
    this.getListCandidats();
  }

  getListCandidats(){
    this.candidatStorage.GetCandidats().subscribe();
    this.ListCandidats = this.candidatService .getListOfCandidats();
    this.subscription =  this.candidatService.SyncListCondidats.subscribe(
       ( Element  ) => {
        for(var i=0;i<Element.length;i++){
          //console.log(Element[i].Code_Ecole)
          if(Element[i].Code_Ecole === undefined ){
            Element[i].Code_Ecole = "3909"
            //Items.push(Element[i]);
          }
        }
        this.ListCandidats = Element;
        this.source.localdata = this.ListCandidats;
        function sortFunction(a,b){
            var dateA = new Date(a.Date_entrer).getTime();
            var dateB = new Date(b.Date_entrer).getTime();
            return dateA > dateB ? -1 : 1;  
        };
        this.ListCandidats.sort(sortFunction);​
        this.dataAdapter = new jqx.dataAdapter(this.source);
      }) 
  }  
  
  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '100%';
    }
      return '100%';
    }
  
  //dataAdapter: any = new jqx.dataAdapter(this.source);

  exportSettings: any = { columnsHeader: true, hiddenColumns: false, characterSet: null, recordsInView: true, fileName: "myDataTable"};
  
  cellClass = (row: any, dataField: any, cellText: any, rowData: any): string => {
    let cellValue = rowData[dataField];
    //console.log(rowData);
    switch (dataField) {
        case 'Montant_total':
          //console.log(cellValue,"------",rowData['Code_Ecole'])
            if (cellValue < rowData['tarif'] ) {
                return 'NotOk';
            }
            return 'Ok';
        case 'status':
            if (cellValue === 'Inapte' ) {
                return 'NotOk';
            }
            if (cellValue === 'Apte' ) {
              return 'Ok';
            }
    }
  }

  imagerenderer = function (row, datafield, value) {
    return '<img class="imgPath" height="60" width="60" src="'+ value +'"/>';
  }

  columns: any[] =
    [{ text: 'Ecole', dataField: 'Code_Ecole', width: '55' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: '', dataField: 'ImagePath', width: '6%' ,pinned: true , cellsalign: 'center' ,align: 'center' ,cellsrenderer: this.imagerenderer },
    { text: 'N° Contrat', dataField: 'NumContrat', width: '10%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'Nom', dataField: 'Nom', width: '9%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'Prenom', dataField: 'Prenom', width: '9%' ,pinned: true , cellsalign: 'center' ,align: 'center' },
    { text: 'D.Inscription', dataField: 'Date_entrer', width: '9%' ,cellsalign: 'center' , align: 'center' ,  cellsFormat: 'yyyy-MM-dd HH:mm' },
    { text: 'CIN', dataField: 'Cin', width: '6%' ,cellsalign: 'center' ,align: 'center' },
    // { text: 'Sexe', dataField: 'Sexe', width: '70',cellsalign: 'center' ,align: 'center' },
    // { text: 'Date N', dataField: 'DateNaissance', width: '90' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    // { text: 'Lieu N', dataField: 'LieuNaissance', width: '90' ,cellsalign: 'center',align: 'center' },
    // { text: 'Adresse', dataField: 'Adresse', width: '12%' ,cellsalign: 'center' ,align: 'center'},
    // { text: 'Ville', dataField: 'Ville', width: '70' ,cellsalign: 'center' ,align: 'center'},
    // { text: 'Telephone', dataField: 'Telephone', width: '80' ,cellsalign: 'center' ,align: 'center'},
    // { text: 'Email', dataField: 'Email', width: '13%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Permis', dataField: 'CategoriePermis', width: '4.7%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Véhicule', dataField: 'Vehicule', width: '6.5%',cellsalign: 'center' ,align: 'center' },
    // { text: 'Tarif', dataField: 'tarif' , width: '60' ,cellsalign: 'center' ,align: 'center'},
    { text: 'Montant', dataField: 'Montant_total', cellClassName: this.cellClass , width: '5%' ,cellsalign: 'center' ,align: 'center'},
    { text: 'D Contrôle', dataField: 'DateCtrl', width: '6%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    { text: 'Ex Th1', dataField: 'DateTh1_Examen', width: '6%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    { text: 'Ex Th2', dataField: 'DateTh2_Examen', width: '6%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    { text: 'Ex P1', dataField: 'DateP1_Examen', width: '6%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    { text: 'Ex P2', dataField: 'DateP2_Examen', width: '6%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    { text: 'Status', dataField: 'status',cellClassName: this.cellClass , width: '5%' ,cellsalign: 'center' , align: 'center' }

    

  ];

  onRowSelect(event: any): void {
    let args = event.args;
    let rowIndex = args.index;
    this.Rowselected = args.key;
    this.StatusEditable = true;
    
  }

  /*
  editCandidat(){
    alert(this.Rowselected);
  //  this.router.navigate(['/Candidats/Edit']);
  }
  */

  printBtnOnClick(): void {
    const DateNow = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    let gridContent = this.myDataTable.exportData('html');
    let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>Auto Ecole Bouharat '+DateNow+'</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  };

  excelExport(): void {
      this.myDataTable.exportData('xls');
  };

  pdfExport(): void {
      this.myDataTable.exportData('pdf');
  };

}