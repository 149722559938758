import { Candidat } from './candidat.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CandidatService{
    constructor(
    ){}
;
    private ListCondidat = [];
    SyncListCondidats = new Subject<any>();

    private ListPointage = [];
    SyncListPointage = new Subject<any>();

    private ListCondidatU = [];
    SyncListCondidatsU = new Subject<any>();
    
    getListOfCandidats(){
        this.SyncListCondidats.next(this.ListCondidat.slice());
        return this.ListCondidat.slice();
    }

    setListOfCandidats(CondidatsValue) {
        this.ListCondidat = CondidatsValue ;
        this.SyncListCondidats.next(this.ListCondidat.slice());
    }

    getListOfCandidatsU(){
        this.SyncListCondidatsU.next(this.ListCondidatU.slice());
        return this.ListCondidatU.slice();
    }

    setListOfCandidatsU(CondidatsValue) {
        this.ListCondidatU = CondidatsValue ;
        this.SyncListCondidatsU.next(this.ListCondidatU.slice());
    }

    getListOfPointages(){
        this.SyncListPointage.next(this.ListPointage.slice());
        return this.ListPointage.slice();
    }

    setListOfPointages(PValue) {
        this.ListPointage = PValue ;
        this.SyncListPointage.next(this.ListPointage.slice());
    }

}