import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MoniteurService{
    constructor(
    ){}
;
    private ListMoniteurs = [];
    SyncListMoniteur = new Subject<any>();
    
    getListOfMoniteurs(){
        this.SyncListMoniteur.next(this.ListMoniteurs.slice());
        return this.ListMoniteurs.slice();
    }

    setListOfMoniteurs(MoniteurssValue) {
        this.ListMoniteurs = MoniteurssValue ;
        this.SyncListMoniteur.next(this.ListMoniteurs.slice());
    }

}