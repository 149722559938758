import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Moniteur } from '../Moniteur.model';
import { MoniteurStorageService } from '../Moniteur-storage.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/authentification/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-monitors',
  templateUrl: './new-monitors.component.html',
  styleUrls: ['./new-monitors.component.css']
})
export class NewMonitorsComponent implements OnInit,OnDestroy {
  NewMoniteur = true;
  faCheck = fa.faCheck;
  faclear = fa.faEraser;
  faCamera = fa.faCamera;
  faError = fa.faExclamationTriangle; 
  faEdit = fa.faUserEdit;
  faDelete = fa.faUserTimes;
  postedBy:string = "";
  Categories = ['A','B','C','D','E'];
  Codes_Ecole = [];
  isLoading = false;
  subscription : Subscription

  showCamera:string;

  cin_edit:string;
  nom_edit:string;
  prenom_edit:string;
  Code_Ecole_edit:string;
  CAP_edit:number;
  PermisNum_edit:string;
  Categorie_edit:string;
  RowId:string;
  opendeleteModal:string;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  imagePath = '../../../assets/img/profile.jpg';
  ImgError = "";
  Profile ;

  constructor(
    private authService: AuthService,
    private moniteurStorage : MoniteurStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private afStorage: AngularFireStorage
  ) { }

  ngOnInit() {

    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem("UserSystem"));

   
    if (userData.email === environment.Manager) {
      this.Profile = {code:'All'}
      this.Codes_Ecole = environment.All
    }else{
      var Current_Code = userData.email.replace('@bouharat.com',"");
       this.Profile = environment.Ecole.find(item => item.code === Current_Code);
       this.Code_Ecole_edit = this.Profile.code
       this.Codes_Ecole.push(this.Profile.code)
    }


    this.RowId = this.route.snapshot.params['key']
    this.subscription = this.moniteurStorage.getMoniteurElement(this.RowId).subscribe(
      element => {
        //console.log(element);
        if( element ){
          this.NewMoniteur = false;
          this.cin_edit = element.Cin;
          this.nom_edit = element.Nom;
          this.prenom_edit = element.Prenom;
          this.PermisNum_edit = element.PermisId;
          this.Categorie_edit = element.Categorie;
          this.CAP_edit = +element.CAP;
          this.Code_Ecole_edit = element.Code_Ecole;
          if(!element.ImagePath){
            this.imagePath = '../../../assets/img/profile.jpg';
          }else{
            this.imagePath = element.ImagePath;
          }
        }
      }
     );
  }


  upload(event){
    this.ImgError = "";
    //const id=Math.random().toString(36).substring(2);
    const id = event.target.files[0].name;
    this.ref = this.afStorage.ref(id);
    //console.log(event.target.files[0]);
    var ImgType = event.target.files[0].type.split('/')[0];    
    
    if(ImgType !== 'image') {
      this.ImgError = "Désolé, invalide image";
      return;
    } 
    
    if(event.target.files[0]){
      this.imagePath = '../../../assets/img/loading.gif';
      this.ref.put(event.target.files[0])
      .then(snapshot => {
          return snapshot.ref.getDownloadURL();   // Will return a promise with the download link
      })
      .then(downloadURL => {
         //console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
         this.imagePath = downloadURL;
         return downloadURL;
      })
      .catch(error => {
        this.ImgError = "Une Erreur lors de la téléchargement de l'image";
         // Use to signal error if something goes wrong.
        // console.log(`Failed to upload file and get link - ${error}`);
      });
    }
  }

   onReset(form: NgForm){
    form.reset();
   }

   ngOnDestroy(){
      if(this.subscription) {
        this.subscription.unsubscribe();
      }
   }

  onCloseModal(){
    this.opendeleteModal = null ;
    this.showCamera = null ;
  }
  
  onSubmit(form: NgForm) {    
    if( this.NewMoniteur ){
      this.AddNewMoniteur(form,'new');
      var acct = form.value.cin.toLowerCase().trim();
      const newEmailUser = "mon."+acct+"@bouharat.com";
      this.authService.signUp(newEmailUser,acct)
      .subscribe(
        resData => {
          // console.log(resData);
        },
        errorMessage => {
          //this.error = errorMessage;
        }
      );
    }else{
      //console.log("Mode Update"+this.RowId);
      this.AddNewMoniteur(form,'update',this.RowId);
      // this.DeleteMoniteur();
    }
  }

  DeleteMoniteur(){
    this.moniteurStorage.deleteMoniteurElement(this.RowId);
    this.router.navigate(['/Moniteurs']);
  }

  getImgLink($event){
    if($event){
      this.imagePath = $event;
    }
  }

  AddNewMoniteur(form: NgForm,type:string,key?:string){
    this.postedBy = this.authService.getSessionLogged();
    this.isLoading = true;
    const NewMoniteur = new Moniteur(form.value.cin,form.value.nom,form.value.prenom,form.value.PermisNum,form.value.CAP,form.value.Categorie,new Date(),this.imagePath,this.postedBy , form.value.Code_Ecole);
   // console.log(NewMoniteur);
    if(type === 'new'){
      this.moniteurStorage.SaveMoniteur(NewMoniteur)
      .subscribe(response => {
        this.isLoading = false;
       });
    }
    if(type === 'update'){
      this.moniteurStorage.UpdateMoniteur(key,NewMoniteur)
      .subscribe(response => {
        this.isLoading = false;
       });
    }

  }



}
