import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  AfterViewInit,
  AfterContentInit,
  Input,
} from "@angular/core";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { CandidatStorageService } from "src/app/candidats/condidat-storage.service";
import { CandidatService } from "src/app/candidats/candidat.service";
import { Subscription } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ChargesStorageService } from "src/app/charges/charges-storage.service";
import { ChargesService } from "src/app/charges/charges.service";
import { MoniteurStorageService } from "src/app/monitors/Moniteur-storage.service";
import { MoniteurService } from "src/app/monitors/moniteur.service";
import { ReclamationStorage } from "src/app/reclamation/reclamation-storage.service";
import { ReclamationSerivce } from "src/app/reclamation/reclamation.service";
import { VehiculeStorageService } from "src/app/vehicules/Vehicule-storage.service";
import { VehiculeService } from "src/app/vehicules/vehicule.service";
import { CarburantStorageService } from "src/app/vehicules/carburant/carburant-storage.service";
import { CarburantService } from "src/app/vehicules/carburant/carburant.service";

@Component({
  selector: "app-back-up",
  templateUrl: "./back-up.component.html",
  styleUrls: ["./back-up.component.css"],
})
export class BackUpComponent implements OnInit {
  faclose = fa.faTimesCircle;
  faSave = fa.faSave;
  subscription: Subscription;

  @Input() BackUp: any;
  @Output() close = new EventEmitter<void>();

  downloadJsonHref: any;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.SaveBackUp(this.BackUp);
  }

  onBackUp() {
    localStorage.setItem("BackUpDate", JSON.stringify(new Date()));
    //this.SaveBackUp(this.BackUp);
  }

  SaveBackUp(File) {
    var theJSON = JSON.stringify(File);
    /*var uri = this.sanitizer.bypassSecurityTrustUrl(
      "data:text/json;charset=UTF-8," + encodeURIComponent(btoa(theJSON))
    );*/
    var uri = this.sanitizer.bypassSecurityTrustUrl(
      "data:text/json;charset=UTF-8," +
        btoa(unescape(encodeURIComponent(theJSON)))
    );
    this.downloadJsonHref = uri;
  }

  onClose() {
    this.close.emit();
  }
}
