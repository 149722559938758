import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';
import { User } from './user.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface AuthResponsData{
    idToken:string;
    email:string;
    refreshToken:string;
    expiresIn:string; 
    localId:string;
    registered?:boolean;
}

@Injectable({providedIn: 'root'})
export class AuthService{

    user = new BehaviorSubject<User>(null);
    private tokenExirationTimer : any;

    constructor( private http : HttpClient , private router : Router ){}
    
    signUp(email,password){
    //console.log(email+"-----"+password);
      return this.http.post<AuthResponsData>(
            'https://identitytoolkit.googleapis.com/v1/accounts:signUp?key='+environment.firebaseAPIKey,
            { 
                email: email,
                password: password,
                returnSecureToken : true
            }
        ).pipe(catchError(this.handelError));
    }


    login(email,password){
       return this.http.post<AuthResponsData>(
           
           'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key='+environment.firebaseAPIKey,
            { 
                email: email,
                password: password,
                returnSecureToken : true
            })
            .pipe(catchError(this.handelError),tap(resData => {
                this.handerAuthertification(resData.email,resData.localId,resData.idToken,+resData.expiresIn);
            })); 
    }

    private handerAuthertification(email:string,user_Id:string,token:string,expiresIn:number){
        const ExpirationDate = new Date(new Date().getTime()+ expiresIn * 1000);
        const user = new User(email,user_Id,token,ExpirationDate);
        this.user.next(user);
        this.autoLogout(expiresIn*1000);
        localStorage.setItem('UserSystem',JSON.stringify(user));
    }

    autoLogin(){
      const userData:{
          email:string;
          id:string;
          _token:string;
          _tokenExpirationDate:string;
      } = JSON.parse(localStorage.getItem('UserSystem')); 
      if(!userData){
          return;
      }
      const loadedUser = new User(userData.email,userData.id,userData._token,new Date(userData._tokenExpirationDate));
      if(loadedUser.token){
          this.user.next(loadedUser);
          const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime() ;
          this.autoLogout(expirationDuration);
      }
    }

    getSessionLogged(){
        const userData:{
          email:string;
          id:string;
          _token:string;
          _tokenExpirationDate:string;
        } = JSON.parse(localStorage.getItem('UserSystem'));
       return userData.email;
    }

    logout(){
        this.user.next(null);
        this.router.navigate(['/auth']);
        localStorage.removeItem('UserSystem');
        if(this.tokenExirationTimer){
            clearTimeout(this.tokenExirationTimer);
        }
        this.tokenExirationTimer = null ;
    }

    autoLogout(ExpirationDuration : number){
        console.log("Expiration Duration :"+ExpirationDuration);
        this.tokenExirationTimer = setTimeout(() => {
           this.logout(); 
        }, ExpirationDuration);
    }

    private handelError(errorRes : HttpErrorResponse){
        //console.log(errorRes);
        let errorMessage = 'Une erreur inconnue est survenue';
        if( !errorRes.error || !errorRes.error.error ){
            return throwError(errorMessage);
        }
        switch (errorRes.error.error.message) {
            case 'INVALID_EMAIL':
                errorMessage = 'Ce compte est invalide';
                break;
            case 'EMAIL_EXISTS':
                errorMessage = 'Ce compte existe déjà';
                break;
            case 'MISSING_PASSWORD':
                errorMessage = 'Le Numero de La carte National Est Vide';
                break;
            case 'EMAIL_NOT_FOUND':
                errorMessage = 'Ce compte n\'existe pas';
                break;
            case 'INVALID_PASSWORD':
                errorMessage = 'Ce mot de passe n\'est pas correct';
                break;
            case 'WEAK_PASSWORD':
                errorMessage = 'La CIN doit comporter au moins 6 caractères.';
                break;
          }
        return throwError(errorMessage);
    }
}