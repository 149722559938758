import { Component, OnInit, AfterContentInit, ViewChild, OnDestroy } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { jqxDataTableComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxdatatable';
import { Subscription } from 'rxjs';
import { ChargesService } from '../charges.service';
import { ChargesStorageService } from '../charges-storage.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-list-charges',
  templateUrl: './list-charges.component.html',
  styleUrls: ['./list-charges.component.css']
})
export class ListChargesComponent implements OnInit , OnDestroy, AfterContentInit {
  
  @ViewChild('myDataTable',{static:false}) myDataTable: jqxDataTableComponent;
  faPrint = fa.faPrint;
  faExcel = fa.faFileExcel;
  faPdf = fa.faFilePdf;
  faEdit = fa.faEdit;
  StatusEditable = false;
  Rowselected:string ;
  ListCharges = [] ;
  dataAdapter: any ;
  source = {
    dataType: 'array',
    //dataType: 'json',
    datafields: [
      { name: 'id', type: 'string' },
      { name: 'label', type: 'string' },
      { name: 'Montant', type: 'int' },
      { name: 'description', type: 'string' },
      { name: 'Date_enter', type: 'date' }
    ],
    id: 'id',
    localdata: this.ListCharges
    //url: '../assets/beverages.txt'
  };
  subscription :Subscription;
  constructor(
    private chargeStorage: ChargesStorageService ,
    private chargeService: ChargesService
  ) { }

  ngOnInit() {
  }

  ngAfterContentInit(){
    this.getListVehicules();
  }

  getListVehicules(){
    this.chargeStorage.GetCharges().subscribe();
    this.ListCharges = this.chargeService.getListOfCharges();
    this.subscription =  this.chargeService.SyncListCharges.subscribe(
       ( Element  ) => {
        this.ListCharges =   Element;
        this.source.localdata = this.ListCharges;
       // console.log(this.source.localdata );
        this.dataAdapter = new jqx.dataAdapter(this.source);
      }) 
  }  
  
ngOnDestroy(){
  this.subscription.unsubscribe();
}

  getWidth() : any {
    if (document.body.offsetWidth < 850) {
      return '100%';
    }
      return '100%';
    }
  
  //dataAdapter: any = new jqx.dataAdapter(this.source);

  exportSettings: any = { columnsHeader: true, hiddenColumns: false, characterSet: null, recordsInView: true, fileName: "myDataTable"};
  

  cellClass = (row: any, dataField: any, cellText: any, rowData: any): string => {
    let cellValue = rowData[dataField];
    switch (dataField) {
        case 'Montant':
            if (cellValue > 500) {
                return 'NotOk';
            }
            return 'Ok';
    }
  }

  columns: any[] =
  [
    { text: 'Titre', dataField: 'label', width: '20%' , cellsalign: 'center' ,align: 'center' },
    { text: 'description', dataField: 'description', width: '45%' ,cellsalign: 'center' ,align: 'center' },
    { text: 'Date d\'entrer', dataField: 'Date_enter', width: '20%' ,cellsalign: 'center' , align: 'center' , cellsFormat: 'yyyy-MM-dd' },
    { text: 'Montant', dataField: 'Montant', align: 'center', cellClassName: this.cellClass, cellsAlign: 'center', width: '15%' }
  ];

  onRowSelect(event: any): void {
    let args = event.args;
    let rowIndex = args.index;
    //console.log(args);
    this.Rowselected = args.key;
    this.StatusEditable = true;
    
  }

  printBtnOnClick(): void {
    const DateNow = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    let gridContent = this.myDataTable.exportData('html');
    let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>Auto Ecole Bouharat '+DateNow+'</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  };

  excelExport(): void {
      this.myDataTable.exportData('xls');
  };

  pdfExport(): void {
      this.myDataTable.exportData('pdf');
  };

}